import { RestSearchRequest } from "@components/search-bar/utils/types";
import { useQuery } from "react-query";
import { KytApiClient } from "../api-client/api-client";
import { KytTransaction } from "../types";

export const useCustomersTransactions = (
  customers: string[],
  options: RestSearchRequest["options"]
) => {
  const fetchTransactions = async (query: RestSearchRequest) => {
    return await KytApiClient.getTransactionsAdvanced(
      query.query,
      query.options
    );
  };

  const newSearchTransactions = useQuery<{
    data: KytTransaction[];
    total: number;
  }>({
    keepPreviousData: true,
    queryKey: ["kyt", "transactions", customers.join(","), options],
    queryFn: async () => {
      if (customers.length === 0) return { data: [], total: 0 };
      const query: RestSearchRequest["query"] = customers.map((c) => ({
        key: "customers",
        not: false,
        operations: [
          {
            op: "regexp",
            value: {
              string: c,
            },
          },
        ],
      }));
      return await fetchTransactions({ query, options });
    },
    cacheTime: 2000,
  });

  return {
    transactions: newSearchTransactions?.data,
    loading: newSearchTransactions.isFetching,
    refresh: newSearchTransactions.refetch,
    fetchTransactions,
  };
};
