import { useGlobalEffect } from "@features/utils";
import { LoadingAtom } from "@features/utils/loading";
import { useCallback } from "react";
import toast from "react-hot-toast";
import { useRecoilState } from "recoil";
import { DocumentsApiClient } from "../api-client/api-client";
import { NoteType } from "../types";
import { CustomerNotesAtom } from "./store";
import { useCustomerDocuments } from "./user-customer-documents";

const PAGE_SIZE = 20;

export const useCustomerNotes = (externalId: string, tags: string[] = []) => {
  const { uploadFile } = useCustomerDocuments(externalId);
  const [notes, setNotes] = useRecoilState(CustomerNotesAtom(externalId));
  const [loading, setLoading] = useRecoilState(
    LoadingAtom("useCustomerNotes-" + externalId)
  );

  const refresh = useCallback(
    async (page?: number) => {
      setLoading(true);
      const files = await DocumentsApiClient.search({
        file_types: ["text"],
        tags: ["notes", ...tags],
        display_name: "algoreg_note_",
        customer_external_id: externalId,
        per_page: PAGE_SIZE,
        on_page: page || 1,
      });
      if (!(files.data?.length >= 0)) {
        toast.error("Error loading Notes");
        return;
      }
      const newNotes: NoteType[] = [];
      for (const file of files.data) {
        const content = await DocumentsApiClient.downloadContent(file);
        newNotes.push({
          id: file.id,
          customer_external_id: file.customer_external_id,
          last_revision_agent_id: file.last_revision_agent_id,
          created_at: file.created_at,
          content,
          tags: file.tags,
        });
      }
      const notesWithTotal = { data: newNotes, total: files.total };
      if ((page || 1) === 1) {
        setNotes(notesWithTotal);
      } else {
        setNotes({
          data: [...notes.data, ...notesWithTotal.data],
          total: notesWithTotal.total,
        });
      }
      setLoading(false);
    },
    [externalId, setLoading, notes, setNotes]
  );

  const nextPage = useCallback(async () => {
    if (notes.data.length < notes.total) {
      await refresh(Math.floor(notes.data.length / PAGE_SIZE) + 1);
    }
  }, [notes, refresh]);

  useGlobalEffect(
    "useCustomerNotesResetFilters",
    () => {
      refresh();
    },
    [externalId]
  );

  const addNote = useCallback(
    async (content: string) => {
      setLoading(true);
      const blob = new Blob([content], { type: "text/plain" });
      const file = new File(
        [blob],
        "algoreg_note_" + new Date(Date.now()).toISOString() + ".md",
        {
          type: "text/plain",
        }
      );
      await uploadFile(file, ["notes", ...tags]);
      await refresh();
      setLoading(false);
    },
    [refresh, uploadFile, setLoading]
  );

  return {
    loading,
    notes: notes.data,
    total: notes.total,
    refresh,
    nextPage,
    addNote,
  };
};
