/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useEffect, useRef, useState } from "react";
import SimpleBar from "simplebar-react";
import { twMerge } from "tailwind-merge";

// @ts-ignore
interface TabsProps extends React.InputHTMLAttributes<HTMLInputElement> {
  tabs: { value: string | number; label: JSX.Element | string }[];
  value: string | number;
  onChange: (value: string | number) => void;
  parentClassName?: string;
}

const defaultTabClassName =
  " text-sm cursor-pointer h-10 px-3 flex items-center border-b-2 border-transparent hover:text-blue-600 transition-colors";
const activeTabClassName = " text-blue-500 border-blue-500 ";
const inactiveTabClassName = " text-zinc-500 ";

export default function Tabs(props: TabsProps) {
  return (
    <>
      <SimpleBar>
        <div
          className={twMerge(
            `flex w-100 border-b border-zinc-200 dark:border-zinc-800 transition-all select-none`,
            props.className
          )}
        >
          {props.tabs.map((tab) => {
            const cl = twMerge(
              defaultTabClassName,
              tab.value === props.value
                ? activeTabClassName
                : inactiveTabClassName,
              props.parentClassName
            );
            return (
              <div
                key={tab.value}
                className={cl}
                onClick={() => props.onChange(tab.value)}
              >
                {tab.label}
              </div>
            );
          })}
        </div>
      </SimpleBar>
    </>
  );
}

export const FloatingTabs = ({
  tabs,
  value,
  onChange,
}: {
  tabs: {
    label: string;
    value: string;
  }[];
  value?: string;
  onChange: (value: string | number) => void;
}) => {
  const navRef = useRef<any>(null);
  const parentRef = useRef<any>(null);
  const [isFloating, setIsFloating] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (navRef.current) {
          if (!entry.isIntersecting) {
            const parentNode = navRef.current.parentNode;
            navRef.current.style.position = "fixed";
            navRef.current.style.top = "50px"; // Adjust as needed
            navRef.current.style.width = parentNode.clientWidth + "px";
            navRef.current.style.left =
              parentNode.getBoundingClientRect().left + "px";
            setIsFloating(true);
          } else {
            navRef.current.style.position = "static";
            setIsFloating(false);
          }
        }
      },
      { threshold: 0 }
    );

    if (parentRef.current) {
      observer.observe(parentRef.current);
    }

    return () => {
      if (parentRef.current) {
        observer.unobserve(parentRef.current);
      }
    };
  }, []);

  return (
    <div ref={parentRef} className="relative w-full mt-2">
      <div
        ref={navRef}
        style={{ zIndex: 10 }}
        className={twMerge(
          "rounded-md overflow-hidden",
          isFloating &&
            "shadow-md border-b border-slate-300 dark:border-slate-600"
        )}
      >
        <Tabs
          parentClassName="mx-2"
          className={twMerge("bg-slate-200 dark:bg-slate-700 px-0 pt-1")}
          tabs={tabs}
          onChange={onChange}
          value={value || "jump"}
        />
      </div>
    </div>
  );
};
