import { Tag } from "@atoms/badge/tag";
import { BaseSmall } from "@atoms/text";
import {
  CalendarDaysIcon,
  DocumentTextIcon,
  HashtagIcon,
  LightBulbIcon,
} from "@heroicons/react/24/outline";
import { SearchField } from "../utils/types";

export const FieldInSearchBar = ({ field }: { field: SearchField }) => (
  <div className="flex flex-col w-full space-y-1">
    <div className="flex flex-row items-center gap-2 w-full">
      <Tag className="bg-blue-500 text-white">{field?.label || ""}</Tag>
      <div className="grow" />
      <Tag className="bg-slate-800 text-white font-mono text-xs">
        {field?.key}
      </Tag>
      <Tag className="bg-slate-500 text-white font-mono text-xs">
        {field?.type === "number" && (
          <>
            <HashtagIcon className="h-3 w-3 inline-block " /> Number
          </>
        )}
        {field?.type === "boolean" && (
          <>
            <LightBulbIcon className="h-3 w-3 inline-block " /> Boolean
          </>
        )}
        {field?.type === "text" && (
          <>
            <DocumentTextIcon className="h-3 w-3 inline-block " /> Text
          </>
        )}
        {field?.type === "date" && (
          <>
            <CalendarDaysIcon className="h-3 w-3 inline-block " /> Date
          </>
        )}
      </Tag>
    </div>
    <BaseSmall>{field?.describe}</BaseSmall>
  </div>
);
