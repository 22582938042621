import { throwBackendError } from "@features/errorresponse";
import { is2XX, useFetch } from "@features/utils";
import { CustomMatrixType, MatrixEntryType } from "../types";

export class CustomMatrixApiClient {
  static getMatrices = async () => {
    const response = await useFetch(`/web/riskscoring/matrices`, {
      method: "GET",
    });
    const data = await response.json();
    return data as CustomMatrixType[];
  };

  static getMatrix = async (id: string) => {
    const response = await useFetch(`/web/riskscoring/matrix/${id}/entries`, {
      method: "GET",
    });
    if (!is2XX(response.status)) {
      await throwBackendError(response);
    }
    const data = await response.json();
    return data as MatrixEntryType[];
  };

  static createMatrice = async (name: string) => {
    const response = await useFetch(`/web/riskscoring/matrix`, {
      method: "POST",
      body: JSON.stringify({ matrix_type: name }),
    });
    return response;
  };

  static editMatrice = async (id: string, name: string) => {
    // NOT IMPLEMENTED BY BACKEND YET
    const response = await useFetch(`/web/riskscoring/matrix/${id}`, {
      method: "POST",
      body: JSON.stringify({ matrix_type: name }),
    });
    return response;
  };

  static deleteMatrice = async (id: string) => {
    // NOT IMPLEMENTED BY BACKEND YET
    try {
      await useFetch(`/web/riskscoring/matrix/${id}`, {
        method: "DELETE",
      });
    } catch (error) {
      throw new Error("Error deleting matrix");
    }
  };

  static createMatrixEntry = async (
    matrixId: string,
    entry: MatrixEntryType
  ) => {
    const response = await useFetch(
      `/web/riskscoring/matrix/${matrixId}/entry`,
      {
        method: "POST",
        body: JSON.stringify({
          key: entry.key,
          value: entry.value,
          label: entry.label,
        }),
      }
    );
    return response;
  };

  static editMatrixEntry = async (matrixId: string, entry: MatrixEntryType) => {
    const response = await useFetch(
      `/web/riskscoring/matrix/${matrixId}/entry/${entry.id}`,
      {
        method: "POST",
        body: JSON.stringify(entry),
      }
    );
    return response;
  };

  static deleteMatrixEntry = async (matrixId: string, entryId: string) => {
    const response = await useFetch(
      `/web/riskscoring/matrix/${matrixId}/entry/${entryId}`,
      {
        method: "DELETE",
      }
    );
    return response;
  };
}
