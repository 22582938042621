import { delayRequest, useGlobalEffect } from "@features/utils";
import { isEmbed } from "@features/utils/use-embed-utils";
import { useCallback } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { LoadingAtom } from "../../utils/loading";
import { DashboardApiClient } from "../api-client/api-client";
import {
  ChatMonitoringAtom,
  KytMonitoringAtom,
  MonitoringTimeseriesAtom,
  MonitoringTimeseriesPeriodAtom,
  OnboardingMonitoringAtom,
  PressMonitoringAtom,
  RiskMonitoringAtom,
  ScanMonitoringAtom,
} from "./store";
import { useHasAccess } from "@features/auth/state/use-access";

export const useRefreshDashboardScan = () => {
  const setScan = useSetRecoilState(ScanMonitoringAtom);
  const refresh = useCallback(async () => {
    setScan(await DashboardApiClient.getScan());
  }, [setScan]);
  return refresh;
};

export const useRefreshDashboardRisk = () => {
  const setRisk = useSetRecoilState(RiskMonitoringAtom);
  const refresh = useCallback(async () => {
    setRisk(await DashboardApiClient.getRisk());
  }, [setRisk]);
  return refresh;
};

export const useRefreshDashboardVid = () => {
  const setVid = useSetRecoilState(OnboardingMonitoringAtom);
  const refresh = useCallback(async () => {
    setVid(await DashboardApiClient.getOnboarding());
  }, [setVid]);
  return refresh;
};

export const useRefreshDashboardChat = () => {
  const setVid = useSetRecoilState(ChatMonitoringAtom);
  const refresh = useCallback(async () => {
    setVid(await DashboardApiClient.getChat());
  }, [setVid]);
  return refresh;
};

export const useRefreshDashboardKyt = () => {
  const setKyt = useSetRecoilState(KytMonitoringAtom);
  const refresh = useCallback(async () => {
    setKyt(await DashboardApiClient.getKyt());
  }, [setKyt]);
  return refresh;
};

export const useRefreshDashboardPress = () => {
  const setPress = useSetRecoilState(PressMonitoringAtom);
  const refresh = useCallback(async () => {
    setPress(await DashboardApiClient.getPress());
  }, [setPress]);
  return refresh;
};

export const useDashboardTimeseries = () => {
  const [period, setPeriod] = useRecoilState(MonitoringTimeseriesPeriodAtom);
  const [data, setData] = useRecoilState(MonitoringTimeseriesAtom);
  const [loading, setLoading] = useRecoilState(
    LoadingAtom("useDashboardTimeseries")
  );
  const refresh = async (from: number, to?: number) => {
    setLoading(true);
    try {
      setData(await DashboardApiClient.getTimeseries(from, to));
    } catch (e) {
      console.error(e);
    }
    setLoading(false);
  };

  useGlobalEffect(
    "useDashboardTimeseries",
    () => {
      if (period[0] !== 0) {
        refresh(period[0], period[1]);
      }
    },
    [period]
  );

  return { setPeriod, period, refresh, data, loading };
};

export const useDashboard = () => {
  const hasAccess = useHasAccess();
  const onboarding = useRecoilValue(OnboardingMonitoringAtom);
  const risk = useRecoilValue(RiskMonitoringAtom);
  const scan = useRecoilValue(ScanMonitoringAtom);
  const chat = useRecoilValue(ChatMonitoringAtom);
  const kyt = useRecoilValue(KytMonitoringAtom);
  const press = useRecoilValue(PressMonitoringAtom);

  const refreshScan = useRefreshDashboardScan();
  const refreshRisk = useRefreshDashboardRisk();
  const refreshVid = useRefreshDashboardVid();
  const refreshChat = useRefreshDashboardChat();
  const refreshKyt = useRefreshDashboardKyt();
  const refreshPress = useRefreshDashboardPress();

  const refresh = useCallback(async () => {
    if (!hasAccess("MONITORING") && !isEmbed()) {
      return;
    }
    delayRequest(
      "dashboard-refresh",
      () => {
        Promise.all([
          refreshScan(),
          refreshRisk(),
          refreshVid(),
          refreshChat(),
          refreshKyt(),
          refreshPress(),
        ]);
      },
      {
        timeout: 500,
        doInitialCall: false,
      }
    );
  }, [
    hasAccess,
    refreshScan,
    refreshRisk,
    refreshVid,
    refreshChat,
    refreshKyt,
    refreshPress,
  ]);

  useGlobalEffect(
    "useDashboard",
    () => {
      refresh();
      if (!isEmbed()) {
        setInterval(() => {
          if (document.visibilityState === "visible") refresh();
        }, 1000 * 60);
      }
    },
    []
  );

  return {
    onboarding,
    risk,
    scan,
    chat,
    kyt,
    press,
    refresh,
  };
};
