import { Tag } from "@atoms/badge/tag";
import { Loader } from "@atoms/loader";
import { Info, InfoSmall } from "@atoms/text";
import { CustomerAllDetailType } from "@features/customers/types";
import { usePressReport } from "@features/press/state/use-press-report";
import {
  pressLevelToNumber,
  REPORT_OVERALL_RISK_LEVEL,
  REPORT_RISK_LEVEL_COLORS,
} from "@features/press/utils";
import { useControlledEffect } from "@features/utils";
import { formatDate, formatTime } from "@features/utils/dates";

export const getPressReview = (customer: CustomerAllDetailType) => {
  const { report, loading, refresh, focusedReportBody, focusReportBody } =
    usePressReport(customer.details.customer.id);

  useControlledEffect(() => {
    refresh();
  }, []);

  useControlledEffect(() => {
    if (report?.latest_revision?.report_body_id) {
      focusReportBody(report.latest_revision.report_body_id);
    }
  }, [report]);
  return { report, loading, focusedReportBody };
};

export const PressReviewContent = ({
  customer,
}: {
  customer: CustomerAllDetailType;
}) => {
  const { report, loading, focusedReportBody } = getPressReview(customer);

  return (
    <>
      {loading && <Loader />}
      {!loading && !report?.latest_revision?.report_body_id && (
        <>
          <Tag
            noColor
            className={
              "text-white capitalize block !py-1 !px-3 bg-" +
              REPORT_RISK_LEVEL_COLORS["none"]
            }
          >
            Never checked
          </Tag>
          <Info className="block mt-2">
            No press report found for this customer.
          </Info>
        </>
      )}
      {!loading && report?.latest_revision?.report_body_id && (
        <>
          {report?.latest_revision?.active_overall_risk_level && (
            <Tag
              noColor
              className={
                " text-white capitalize block mr-1 !py-1 !px-3 bg-" +
                REPORT_RISK_LEVEL_COLORS[
                  report?.latest_revision?.active_overall_risk_level || "none"
                ]
              }
            >
              {report?.latest_revision?.active_overall_risk_level || "Unknown"}
            </Tag>
          )}

          {!report?.latest_revision?.active_overall_risk_level && (
            <Tag
              noColor
              className={
                " text-white capitalize block mr-1 bg-" +
                REPORT_RISK_LEVEL_COLORS[
                  report?.latest_revision?.robot_overall_risk_level || "none"
                ]
              }
            >
              {report?.latest_revision?.robot_overall_risk_level || "Unknown"}{" "}
              to be confirmed by an agent
            </Tag>
          )}

          <Info className="block mt-2 space-x-1">
            {focusedReportBody?.report_categories
              ?.filter((a) => !a.reverted)
              ?.map((a) => (
                <Tag
                  key={a.category}
                  noColor
                  className={
                    " text-white capitalize block mr-1 bg-" +
                    REPORT_RISK_LEVEL_COLORS[a.severity || "low"]
                  }
                >
                  {a.category}
                </Tag>
              ))}
          </Info>

          <Info className="block mt-2">
            {report?.latest_revision.active_overall_risk_level && (
              <>
                Press risk level confirmed by{" "}
                {report?.latest_revision.created_by_agent_name},{" "}
                {formatTime(report?.latest_revision.created_at)}.
              </>
            )}
          </Info>
        </>
      )}
    </>
  );
};

export const PressShortReview = ({
  customer,
}: {
  customer: CustomerAllDetailType;
}) => {
  const { report, loading } = getPressReview(customer);

  const hasUnconfirmedUperLevel =
    pressLevelToNumber(
      report?.latest_revision?.robot_overall_risk_level ||
        REPORT_OVERALL_RISK_LEVEL.NONE
    ) >
    pressLevelToNumber(
      report?.latest_revision?.active_overall_risk_level ||
        REPORT_OVERALL_RISK_LEVEL.NONE
    );

  return (
    <div>
      <span data-tooltip={"Related to bad press screenign"} tabIndex={0}>
        <Info>Press</Info>
      </span>
      <br />
      <div className="overflow-hidden mr-2 text-base">
        {!!loading && <Loader />}
        {!loading && !report?.latest_revision?.active_overall_risk_level && (
          <Tag>N/A</Tag>
        )}
        {!loading && report?.latest_revision?.active_overall_risk_level && (
          <Tag
            noColor
            className={
              " text-white capitalize bg-" +
              (hasUnconfirmedUperLevel
                ? "orange-500"
                : REPORT_RISK_LEVEL_COLORS[
                    hasUnconfirmedUperLevel
                      ? report?.latest_revision?.robot_overall_risk_level
                      : report?.latest_revision?.active_overall_risk_level ||
                        "none"
                  ])
            }
          >
            {hasUnconfirmedUperLevel && "Unconfirmed "}
            {report?.latest_revision?.active_overall_risk_level || "Unknown"}
          </Tag>
        )}
      </div>
      <InfoSmall>
        {report?.latest_revision?.report_body_id
          ? formatDate(report?.latest_revision?.created_at)
          : "Never checked"}
      </InfoSmall>
    </div>
  );
};
