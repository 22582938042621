import { Info } from "@atoms/text";
import _ from "lodash";
import seedrandom from "seedrandom";
import { twMerge } from "tailwind-merge";

type AvatarProps = {
  avatar: string;
  size: 5 | 28 | 14 | 48;
  type: "video" | "image";
  shape?: "square" | "circle";
  fallback?: string;
};

export const getGradient = (name: string) => {
  const seed = Math.floor(seedrandom(name)() * 100000000) % 360;
  const canvas: HTMLCanvasElement = document.createElement("canvas");
  canvas.width = 64;
  canvas.height = 64;
  const ctx = canvas.getContext("2d");
  const gradient = ctx!.createLinearGradient(64, 64, 0, 0);
  gradient.addColorStop(0, "hsl(" + seed + ", 80%, 50%)");
  gradient.addColorStop(1, "hsl(" + Math.abs(seed - 60) + ", 80%, 50%)");
  ctx!.fillStyle = gradient;
  ctx!.fillRect(0, 0, 64, 64);
  const b64 = canvas.toDataURL("image/jpeg");
  return b64;
};

export default function Avatar({
  type = "image",
  size = 14,
  ...props
}: any & AvatarProps) {
  const className = twMerge(
    "shrink-0 inline-block overflow-hidden bg-slate-200",
    props.shape === "square" ? "rounded-sm" : "rounded-full",
    `h-${size} w-${size}`,
    props.className
  );

  if (!props.loading && (props.avatar || props.src)) {
    return (
      <div
        className={className}
        {..._.omit(props, "avatar", "className", "src")}
        style={
          type === "image"
            ? {
                backgroundPosition: "center",
                backgroundSize: "cover",
                backgroundImage: "url(" + (props.avatar || props.src) + ")",
              }
            : {}
        }
      >
        {type === "video" && (
          <video
            src={props.avatar}
            height="100%"
            width="100%"
            className="h-full w-full object-cover"
            autoPlay
            loop
            muted
          >
            Your browser does not support the video tag.
          </video>
        )}
      </div>
    );
  }

  if (props.loading) {
    return (
      <div className={className}>
        <div className="h-full w-full flex items-center justify-center">
          <svg
            className="animate-spin h-5 w-5"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              className="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              strokeWidth="4"
            />
            <path
              className="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            />
          </svg>
        </div>
      </div>
    );
  }

  if (props.fallback) {
    return (
      <span
        className={
          className +
          " flex items-center justify-center text-white background-cover "
        }
        style={{ backgroundImage: `url(${getGradient(props.fallback)})` }}
        {..._.omit(props, "avatar", "className", "src")}
      >
        <Info
          noColor
          className={
            size < 8
              ? "text-xxs"
              : size < 14
              ? "text-sm"
              : size < 28
              ? "text-base"
              : "text-lg"
          }
        >
          {props.fallback
            .split(" ")
            .filter((a: string) => a.trim())
            .map((a: string) => a[0].toUpperCase())
            .slice(0, 2)
            .join("")}
        </Info>
      </span>
    );
  }

  return (
    <span
      className={className}
      {..._.omit(props, "avatar", "className", "src")}
    >
      <svg
        className="h-full w-full text-slate-400 bg-slate-200"
        fill="currentColor"
        viewBox="0 0 24 24"
      >
        <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
      </svg>
    </span>
  );
}
