export type ScenarioListType = {
  id: number;
  language: string;
  label: string;
  code: string;
  enabled: boolean;
  randomized: boolean;
  score_threshold: number;
  result_label: string;
};

export type SaveScenarioInput = {
  client_id: number;
  language: string;
  label: string;
  code: string;
  enabled: boolean;
  randomized: boolean;
  score_threshold: number;
  questions: [];
  status: [];
};

export enum QuestionType {
  Information = "information",
  Identity = "identity",
  Face = "face",
  File_Upload = "file_upload",
}

export type Question = {
  id: number;
  position: number;
  type: QuestionType;
  label: string;
  description_localized: {
    [key: string]: string;
  };
  eliminatory: boolean;
  coefficient: number;
  auto_start_recognition: boolean;
  face_number?: number;
  antibot_type?: number;

  // information
  possible_answers: string[] | null;
  should_not_contain: string[] | null;

  // upload file
  max_pages?: number;
  frame_type?: string;
  extract_models?: string[];
};

export type QuestionUpdate = {
  id: number;
  position: number;
  type: QuestionType;
  label: string;
  description_localized: { language: string; description: string }[];
  eliminatory: boolean;
  coefficient: number;
  auto_start_recognition: boolean;
  antibot_type?: number;

  // information
  information_data?: {
    possible_answers: string[] | null;
    should_not_contain: string[] | null;
  };

  face_data?: {
    face_number?: number;
  };
  // upload file
  upload_data?: {
    max_pages?: number;
    frame_type?: string;
    extract_models?: string[];
  };
};

export type Geolocation = {
  enabled: boolean;
  precise_geolocation_undefined: string;
  country_different: string;
  ip_to_geolocation_max_distance: number;
  ip_to_geolocation_max_distance_exceeded: string;
};

export type Captcha = {
  enabled: boolean;
  behavior: string;
  threshold: number;
};

export type Watchlist = {
  enabled: boolean;
  precise_match: string;
  fuzzy_match: string;
};

export enum TriggerTypes {
  SetToPendingTrigger = "set_to_pending_triggers",
  IgnoreTrigger = "ignore_triggers",
}

export type TriggerType =
  | TriggerTypes.SetToPendingTrigger
  | TriggerTypes.IgnoreTrigger;

export enum TriggerStates {
  Warning = "warning",
  Undefined = "undefined",
  Error = "error",
}
export type TriggerState =
  | TriggerStates.Error
  | TriggerStates.Undefined
  | TriggerStates.Warning;

export type TriggerDefinition = {
  [key in TriggerType]: {
    [key in TriggerState]: string[];
  };
};

export type ScoringPreferences = {
  threshold: {
    fail: number;
    success: number;
  };
  questions: null | string[]; // Change 'string[]' to the specific type if questions can have a type other than 'null'
  geolocation: Geolocation;
  captcha: Captcha;
  watchlist: Watchlist;
  face: TriggerDefinition;
  id: TriggerDefinition & {
    document_regexes?: DocumentRegexes;
    first_name_coefficient: number;
    last_name_coefficient: number;
    birth_date_coefficient: number;
  };
};

export type DocumentRegexes = {
  accepted: string[];
  pending: string[];
  refused: string[];
};

export type IntegrationPreferences = {
  callback: {
    url: string;
    user: string;
    password: string;
  };
  return_url: string;
  translation_override_json: string;
  style_override_css: string;
};

export type SessionPreferences = {
  randomized: boolean;
  record_video: boolean;
};

export type ScenarioType = {
  id: number;
  language: string;
  label: string;
  code: string;
  result_label: string;
  enabled: boolean;
  randomized: boolean;
  score_threshold: number;
  status: number[];
  questions: Question[];
  scoring_preferences: ScoringPreferences;
  integration_preferences: IntegrationPreferences;
  session_preferences: SessionPreferences;
  ask_otp: boolean;

  // Calculated field
  languages: string[];
};

export type ScenarioUpdateType = {
  id: number;
  language: string;
  label: string;
  code: string;
  result_label: string;
  enabled: boolean;
  randomized: boolean;
  score_threshold: number;
  status: number[];
  questions: QuestionUpdate[];
  scoring_preferences: ScoringPreferences;
  integration_preferences: IntegrationPreferences;
  session_preferences: SessionPreferences;
  ask_otp: boolean;
};

export type RuleTrigger = TriggerType;
export type RuleState = TriggerState;

export type Rule = {
  identifier: string;
  trigger: RuleTrigger;
  states: RuleState[];
};
