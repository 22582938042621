export type ErrorResponse = {
  errors: APIError[];
};

type APIError = {
  message: string;
  code: string;
  deprecated: boolean;
  will_be_deprecated: boolean;
};

export const getLeastDeprecatedError = (e: ErrorResponse): APIError => {
  if (e.errors.length === 0) {
    return {
      message: "Error missing",
      code: "",
      deprecated: true,
      will_be_deprecated: true,
    } as APIError;
  }

  let error: APIError = e.errors[0];
  for (let i = 1; i < e.errors.length; i++) {
    if (error.deprecated && !e.errors[i].deprecated) {
      error = e.errors[i];
    }
  }
  return error;
};

export const throwBackendError = async (r: Response) => {
  const errorResponse = (await r.json()) as ErrorResponse;
  if (errorResponse.errors.length > 0)
    throw new Error(getLeastDeprecatedError(errorResponse).message);
  else throw new Error("Internal server error");
};
