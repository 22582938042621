import { DesiredReportCategory, PressSettings } from "./types";

export enum CATEGORY_SEVERITY {
  LOW = "low",
  MEDIUM = "medium",
  HIGH = "high",
}

export const SEVERITY_COLORS: any = {
  low: "green-500",
  medium: "yellow-500",
  high: "red-500",
};

export enum CATEGORY_DEGREE_OF_CERTAINTY {
  UNSURE = "unsure",
  LIKELY = "likely",
  CONFIRMED = "confirmed",
}

export const CERTAINTY_COLORS: any = {
  unsure: "slate-500",
  likely: "yellow-500",
  confirmed: "green-500",
};

export enum REPORT_EDD_STATE {
  NO_STATUS = "No_status",
  PENDING = "Pending",
  ACTION_REQUIRED = "Action_required",
}

export const REPORT_EDD_STATUS_COLORS: any = {
  No_status: "slate-500",
  Pending: "blue-500",
  Action_required: "orange-500",
};

export const REPORT_EDD_STATUS_TO_UINT_BACKEND: any = {
  No_status: 1,
  Pending: 2,
  Action_required: 3,
};

export const REPORT_EDD_STATUS_FROM_UINT_BACKEND: any = {
  1: "No_status",
  2: "Pending",
  3: "Action_required",
};

export enum REPORT_OVERALL_RISK_LEVEL {
  NONE = "none",
  LOW = "low",
  MEDIUM = "medium",
  HIGH = "high",
}

export const REPORT_RISK_LEVEL_COLORS: any = {
  none: "slate-500",
  low: "yellow-500",
  medium: "orange-500",
  high: "red-500",
};

export const pressLevelToNumber = (
  level: REPORT_OVERALL_RISK_LEVEL
): number => {
  switch (level) {
    case REPORT_OVERALL_RISK_LEVEL.NONE:
      return 0;
    case REPORT_OVERALL_RISK_LEVEL.LOW:
      return 1;
    case REPORT_OVERALL_RISK_LEVEL.MEDIUM:
      return 2;
    case REPORT_OVERALL_RISK_LEVEL.HIGH:
      return 3;
    default:
      return 0;
  }
};

export const splitPressSettingsUrls: (urlsInput: string) => string[] = (
  urlsInput: string
) => {
  return urlsInput.split(/[\s,]+/); // split input by comma or any whitespace character
};

export const splitAndRemoveBlankPressSettingsUrls: (
  urlsInput: string
) => string[] = (urlsInput: string) => {
  return splitPressSettingsUrls(urlsInput).filter((str) => str.length > 0); // split input by comma or any whitespace character and remove eventual empty urls
};

export const validateMinimumMonthOldSetting: (
  minMonthOld: number
) => boolean = (minMonthOld: number) => {
  return Number.isInteger(minMonthOld);
};

export const validateDesiredReportCategorySetting: (
  drcSetting: DesiredReportCategory
) => boolean = (drcSetting: DesiredReportCategory) => {
  let result = true;
  const labelWithoutWhiteSpace = drcSetting.label.replace(/[\s,]+/g, "");
  if (labelWithoutWhiteSpace === "") {
    result = false;
  }
  return result;
};

export const validatePressSettings: (
  pressSettings: PressSettings
) => boolean = (pressSettings: PressSettings) => {
  let result = true;
  pressSettings.desired_categories.map((drc) => {
    if (validateDesiredReportCategorySetting(drc) === false) {
      result = false;
    }
  });
  if (
    validateMinimumMonthOldSetting(pressSettings.website_minimum_month_old) ===
    false
  ) {
    result = false;
  }
  return result;
};
