import { Button } from "@atoms/button/button";
import { Loader } from "@atoms/loader";
import { Base, InfoSmall } from "@atoms/text";
import { useHasAccess } from "@features/auth/state/use-access";
import { useRealtimeThread, useThread } from "@features/inbox/state/use-thread";
import { useThreadMessages } from "@features/inbox/state/use-thread-messages";
import {
  InboxMessage,
  InboxThreadStatus,
  ThreadHistoryItemType,
} from "@features/inbox/types";
import {
  CheckCircleIcon,
  DocumentIcon,
  PencilSquareIcon,
} from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useSetRecoilState } from "recoil";
import EditThreadModal from "../components/modals/edit-thread-modal";
import ThreadsCommentsInput from "./comments-input";
import ThreadHistoryAssignmentCard from "./components/history-assignment-card";
import ThreadHistoryItemCard from "./components/history-item-card";
import ThreadMessageCard from "./components/message-card";
import { ThreadHeader } from "./header";

import { ErrorBoundary } from "@views/error-boundary";
import { CreateInboxMessageModalAtom } from "../components/modals/create-message-modal";

export default function ThreadMessages(props: { threadId: string }) {
  const { thread } = useThread(props.threadId);

  if (!thread) return <></>;

  return <ThreadMessagesView threadId={props.threadId} />;
}

let interval: any = 0;

const ThreadMessagesView = (props: { threadId: string }) => {
  const { thread } = useThread(props.threadId);

  const { update: updateThread } = useThread(thread?.id || "");
  const {
    feed,
    loading: loadingMessages,
    refresh,
  } = useThreadMessages(thread!.id);
  useRealtimeThread(thread!.id);
  const [modalUpdateThread, setModalUpdateThread] = useState(false);
  const setCreateModal = useSetRecoilState(CreateInboxMessageModalAtom);
  const hasAccess = useHasAccess();

  useEffect(() => {
    refresh();
  }, [refresh, props.threadId]);

  //Refresh every minutes in case websocket fails
  useEffect(() => {
    clearInterval(interval);
    interval = setInterval(() => {
      refresh({
        _silent: true,
      });
    }, 120000);
    return () => clearInterval(interval);
  }, [refresh]);

  const loading = loadingMessages && feed.length === 0;

  if (!thread) return <></>;

  return (
    <>
      <EditThreadModal
        thread={thread}
        open={modalUpdateThread}
        onSave={async (input) => {
          await updateThread(thread.id, input);
          toast.success("Thread updated");
        }}
        onClose={() => {
          setModalUpdateThread(false);
        }}
      />
      <div className="flex flex-row h-full max-h-full overflow-visible">
        <div className="flex flex-col grow h-full w-full">
          <ThreadHeader
            threadId={thread.id}
            onEditThread={() => setModalUpdateThread(true)}
          />

          {loading && (
            <div className="flex flex-col items-center justify-center h-full">
              <Loader />
            </div>
          )}

          {!loading && feed.length === 0 && (
            <div className="flex flex-col items-center space-y-2 justify-center h-full">
              <InfoSmall>This thread is empty</InfoSmall>
              <Base>No messages found</Base>
              <Button
                size="sm"
                className="space-x-1"
                onClick={() => {
                  setCreateModal({
                    open: true,
                    threadId: thread.id,
                  });
                }}
              >
                <PencilSquareIcon className="w-4 h-4 mr-2 -ml-1" />
                New message
              </Button>
            </div>
          )}

          {!loading && feed.length > 0 && (
            <ErrorBoundary>
              <div className="px-6 grow flex flex-col-reverse overflow-y-scroll">
                {/* // last element */}
                {thread.status !== InboxThreadStatus.CLOSED && (
                  <div
                    className="ml-6 border-l-2 border-dashed border-slate-300"
                    style={{ minHeight: "40px" }}
                  ></div>
                )}
                {thread.status === InboxThreadStatus.CLOSED && (
                  <>
                    <div className="ml-2.5 bg-blue-500 w-fit p-2 rounded-full">
                      <CheckCircleIcon className="w-4 h-4 text-white" />
                    </div>
                    <div className="ml-6 h-4 border-l-2 border-slate-300 pt-6"></div>
                  </>
                )}
                {feed.map(({ type, data: item }) => {
                  /** Message block */
                  if (type === "message") {
                    return (
                      <div className="flex flex-col " key={item.id}>
                        <div className="ml-6 h-4 border-l-2 border-slate-300 pt-6"></div>
                        <ThreadMessageCard
                          key={"message_id_" + item.id}
                          threadId={thread.id}
                          message={item}
                          replyTo={
                            item.reply_to_external_message_id
                              ? (feed.find(
                                  ({ type, data }) =>
                                    type === "message" &&
                                    data.external_message_id ===
                                      item.reply_to_external_message_id
                                )?.data as InboxMessage)
                              : undefined
                          }
                        />
                      </div>
                    );
                  }

                  /** Thread change block */
                  if (type === "thread") {
                    return (
                      <ThreadHistoryItemCard
                        key={"history_thread_rev_" + item.revision_number}
                        item={item}
                        history={
                          feed
                            .filter(({ type }) => type === "thread")
                            .map((a) => a.data) as ThreadHistoryItemType[]
                        }
                      />
                    );
                  }

                  /** Assignment change block */
                  if (type === "assignment") {
                    return (
                      <ThreadHistoryAssignmentCard
                        key={"assignements_rev_" + item.revision_number}
                        item={item}
                      />
                    );
                  }
                  return <></>;
                })}
                {/* first element */}
                <div className="bg-blue-500 w-fit ml-2 p-2 mt-2 rounded-full">
                  <DocumentIcon className="w-4 h-4 text-white" />
                </div>
              </div>
            </ErrorBoundary>
          )}

          {hasAccess("CHAT_AGENT") && (
            <ThreadsCommentsInput
              threadId={thread.id}
              disabled={loading}
              onCreateMessage={() => {
                const lastMessage = feed.filter(
                  ({ type }) => type === "message"
                )[0]?.data as InboxMessage;
                setCreateModal({
                  open: true,
                  threadId: thread.id,
                  message: {
                    subject: lastMessage?.subject || "",
                    to:
                      (lastMessage?.delivery === 4
                        ? lastMessage.from
                        : lastMessage.to) || "",
                  },
                });
              }}
            />
          )}
        </div>
      </div>
    </>
  );
};
