import { Checkbox } from "@atoms/input/input-checkbox";
import { InputLabel } from "@atoms/input/input-decoration-label";
import SelectMultiple from "@atoms/input/input-select-multiple";
import { SearchBar } from "@components/search-bar";
import {
  OutputQuery,
  RestSearchQuery,
  RestSearchRequest,
} from "@components/search-bar/utils/types";
import { buildQueryFromMap } from "@components/search-bar/utils/utils";
import { useAgents } from "@features/agents/state/use-agents";
import { useReviewGroups } from "@features/agents/state/use-review-groups";
import { AuthJWT } from "@features/auth/jwt";
import { useAuth } from "@features/auth/state/hooks";
import { useCustomFields } from "@features/custom-fields/state/use-custom-fields";
import { CustomMatrixApiClient } from "@features/custom-matrix/api-client/api-client";
import { useCustomers } from "@features/customers/state/use-customers";
import { CustomerSearchField } from "@features/customers/types";
import {
  CUSTOMERS_EDD_STATES,
  RISK_CUSTOMER_CODES_LIST,
  STATES_BACKEND_NAMES,
} from "@features/customers/utils";
import { useMessagesLabels } from "@features/messages-labels/state/use-messages-labels";
import { useScenarios } from "@features/scenarios/state/use-scenarios";
import { useControlledEffect } from "@features/utils";
import _, { isEqual } from "lodash";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { atomFamily, useRecoilState } from "recoil";

type SearchFiltersType = Partial<{
  search: string;
  review_groups: string[] | null;
  only_without_review_groups: boolean;
  assigned_to_member_id: number[] | null;
  only_without_assignment: boolean;
  edd_state: string[];
  risk: string[];
  disabled: boolean;
}>;

const CustomersSearchAtom = atomFamily<SearchFiltersType, number>({
  key: "CustomersSearchAtom",
  default: (clientId: number) =>
    JSON.parse(localStorage.getItem(`filters-${clientId}-customers`) || "[]"),
  effects_UNSTABLE: (clientId: number) => [
    ({ onSet }) => {
      onSet((newValue) => {
        localStorage.setItem(
          `filters-${clientId}-customers`,
          JSON.stringify(newValue)
        );
      });
    },
  ],
});

export const CustomersListFilters = ({
  value,
  onChange,
}: {
  value: RestSearchRequest;
  onChange: (value: RestSearchRequest) => void;
}) => {
  const { clientId } = useAuth();
  const { schema } = useCustomers();
  const [advancedQuery, setAdvancedQuery] = useState<RestSearchQuery[]>(
    value.query || []
  );
  const [filters, setFilters] = useRecoilState(
    CustomersSearchAtom(clientId || 0)
  );
  const { reviewGroups } = useReviewGroups({ access: "CUSTOMER_READ" });
  const { members } = useAgents();
  const { scenarios } = useScenarios();
  const { labels: chatCategories } = useMessagesLabels();
  const location = useLocation();

  const { fields: customFields } = useCustomFields();
  const customersCustomFields = customFields.filter(
    (field) => field.field_source === 3
  );

  useEffect(() => {
    onChange({
      query: [
        ...advancedQuery,
        ...buildQueryFromMap({
          disabled: filters.disabled ? undefined : false,
          review_groups: filters.only_without_review_groups
            ? undefined
            : filters.review_groups,
          assignees: filters.only_without_assignment
            ? undefined
            : filters.assigned_to_member_id,
          edd_state: filters.edd_state,
          overall_risk: filters.risk,
        }),
        ...buildQueryFromMap({
          review_groups: filters.only_without_review_groups
            ? reviewGroups.map((a) => a)
            : undefined,
          assignees: filters.only_without_assignment
            ? members.map((a) => a.id)
            : undefined,
        }).map((a) => ({ ...a, not: true })),
      ],
      options: { ...value.options, offset: 0 },
    });
  }, [advancedQuery, filters]);

  useControlledEffect(() => {
    const a = new URLSearchParams(window.location.search);
    if (a.get("search"))
      setFilters({
        ...filters,
        search: a.get("search") || "",
      });
  }, [location, setFilters]);

  // Need members actives
  const membersActives = members.filter(
    (agent) =>
      agent.active &&
      agent.clients.filter(
        (client) => client.active && client.id === AuthJWT.clientId
      ).length > 0
  );

  return (
    <div className="flex flex-col space-y-2 xl:flex-row xl:space-x-2 xl:space-y-0">
      <div className="grow">
        <InputLabel
          label="Search"
          input={
            <div className="flex flex-row">
              <SearchBar
                schema={{
                  table: "customers",
                  fields: [
                    ...schema.filter(
                      (field) => !field.possible_additional_keys?.length
                    ),
                    ...schema
                      .filter((field) => field.possible_additional_keys?.length)
                      .reduce((acc: any[], field) => {
                        return [
                          ...acc,
                          ...field.possible_additional_keys.map((key) => ({
                            value_type: key.value_type,
                            external_key: `${field.external_key}.${key.key}`,
                            display_name: `${field.external_key}: ${key.display_name}`,
                            allowed_ops: field.allowed_ops,
                          })),
                        ];
                      }, [] as CustomerSearchField[]),
                  ]
                    .filter((field) => field.external_key !== "assignees") // Due to a strange bug with this one
                    .map((field) => {
                      let type:
                        | "number"
                        | "boolean"
                        | "text"
                        | "date"
                        | `type:${string}`;

                      switch (field.value_type) {
                        case "string":
                          type = "text";
                          break;
                        case "number":
                          type = "number";
                          break;
                        case "boolean":
                          type = "boolean";
                          break;
                        case "date":
                          type = "date";
                          break;
                        // Add more cases if needed
                        default:
                          type = `type:${field.value_type}`;
                      }

                      const customField = customersCustomFields?.find(
                        (a) => a.label === field.display_name
                      );
                      const displayName =
                        customField?.header_name || field.display_name;
                      const isMatrix =
                        customField?.format?.type?.match(/matrix_/);

                      return {
                        key: field.external_key,
                        label: displayName,
                        keywords: (
                          displayName +
                          " " +
                          field.display_name.replace(/ /gm, "") +
                          " " +
                          field.external_key
                        ).split(/( |_)/),
                        allowed_ops: field.allowed_ops.filter((a: string) =>
                          isMatrix ? a !== "regexp" : true
                        ),
                        type,
                      };
                    }),
                }}
                onSuggest={async (_table: string, column: string) => {
                  if (column === "account_type") {
                    return [
                      { value: "F", label: "Firm" },
                      { value: "N", label: "Individual" },
                    ];
                  }

                  if (column === "assignees") {
                    return membersActives.map((a) => ({
                      value: a.id,
                      label: a.name,
                    }));
                  }

                  if (
                    [
                      "computed_fields_labels.vid_failed",
                      "computed_fields_labels.vid_success",
                      "computed_fields_labels.vid_outdated",
                      "computed_fields_labels.vid_in_progress",
                    ].includes(column)
                  ) {
                    return (scenarios?.data || []).map((a) => ({
                      value: a.result_label || a.code,
                      label: [a.label, a.result_label || a.code]
                        .filter(Boolean)
                        .join(" "),
                    }));
                  }

                  if (
                    [
                      "computed_fields_labels.open_chat_categories",
                      "computed_fields_labels.closed_chat_categories",
                    ].includes(column)
                  ) {
                    return (chatCategories || []).map((a) => ({
                      value: a.code,
                      label: a.name || a.code,
                    }));
                  }

                  if (column === "review_groups") {
                    return reviewGroups.map((a) => ({ value: a, label: a }));
                  }

                  const matrixCustomFieldId = customersCustomFields
                    .find(
                      (a) =>
                        column === "custom_fields_labels." + a.label &&
                        a.format?.type?.match(/matrix_/)
                    )
                    ?.format?.type?.split("_")?.[1];
                  if (matrixCustomFieldId) {
                    try {
                      const res = await CustomMatrixApiClient.getMatrix(
                        matrixCustomFieldId
                      );
                      return _.sortBy(
                        res?.map((a) => {
                          return {
                            value: a.key,
                            label: a.label,
                          };
                        }),
                        "label"
                      );
                    } catch (error) {
                      console.log("error getting matrix", error);
                    }
                  }

                  const field = schema.find((f) => f.external_key === column);

                  if (!field?.possible_values) return [];

                  return [
                    ...field.possible_values.map((v) => ({
                      value: v,
                      label: v,
                    })),
                    ...((field.allowed_ops || []).includes("is_null")
                      ? [{ value: null, label: "None" }]
                      : []),
                  ];
                }}
                onChange={(str: OutputQuery) => {
                  if (str.valid && !isEqual(str.fields, advancedQuery)) {
                    setAdvancedQuery(str.fields);
                  }
                }}
              />
            </div>
          }
        />
      </div>
      <div className="flex space-y-2 flex-col lg:flex-row lg:space-x-2 lg:space-y-0 w-full xl:w-1/2">
        <InputLabel
          className="w-full"
          label="Review groups"
          input={
            <SelectMultiple
              highlight
              value={
                filters.only_without_review_groups
                  ? ["none"]
                  : filters.review_groups || []
              }
              options={[
                { value: "none", label: "(None)" },
                ..._.sortBy(
                  reviewGroups.map((c) => ({
                    value: c,
                    label: c,
                  })),
                  "label"
                ),
              ]}
              onChange={(e) =>
                setFilters({
                  ...filters,
                  only_without_review_groups:
                    e.includes("none") && filters.only_without_review_groups
                      ? // aka : already included "none"
                        false // unselect "none"
                      : e.includes("none"),
                  review_groups:
                    e.includes("none") && filters.only_without_review_groups
                      ? e.splice(e.indexOf("none"), 1) && e
                      : !e.includes("none")
                      ? e
                      : null,
                })
              }
            />
          }
        />
        <InputLabel
          className="w-full"
          label="Assigned to"
          input={
            <SelectMultiple
              highlight
              value={
                filters.only_without_assignment
                  ? ["none"]
                  : filters.assigned_to_member_id
                  ? filters.assigned_to_member_id.map((a) => `${a}`)
                  : []
              }
              options={[
                { value: "none", label: "(None)" },
                ..._.sortBy(
                  membersActives.map((agent) => ({
                    value: agent.id.toString(),
                    label: agent.name,
                  })) || [],
                  "label"
                ),
              ]}
              onChange={(e) =>
                setFilters({
                  ...filters,
                  only_without_assignment:
                    e.includes("none") && filters.only_without_assignment // aka : already included "none"
                      ? false // unselect "none"
                      : e.includes("none"),
                  assigned_to_member_id:
                    e.includes("none") && filters.only_without_assignment
                      ? e.splice(e.indexOf("none"), 1) &&
                        e.map((i) => {
                          return parseInt(i);
                        })
                      : !e.includes("none")
                      ? e.map((i) => {
                          return parseInt(i);
                        })
                      : null,
                })
              }
            />
          }
        />
        <InputLabel
          label="Status"
          className="w-full"
          input={
            <SelectMultiple
              highlight
              value={filters.edd_state?.map((a) => `${a}`) || []}
              onChange={(e) =>
                setFilters({
                  ...filters,
                  edd_state: e || [],
                })
              }
              options={Object.keys(CUSTOMERS_EDD_STATES).map((state) => ({
                value: STATES_BACKEND_NAMES[state],
                label: CUSTOMERS_EDD_STATES[state],
              }))}
            />
          }
        />
        <InputLabel
          label="Risk"
          className="w-full"
          input={
            <SelectMultiple
              highlight
              value={filters.risk?.map((risk) => `${risk}`) || []}
              onChange={(e) =>
                setFilters({
                  ...filters,
                  risk: e || [],
                })
              }
              options={RISK_CUSTOMER_CODES_LIST.map((risk) => ({
                value: risk.value,
                label: risk.label,
              }))}
            />
          }
        />
        <InputLabel
          label="Disabled"
          className="w-full"
          input={
            <div className="pt-2">
              <Checkbox
                label="Include"
                value={filters.disabled}
                onChange={(s) => setFilters({ ...filters, disabled: s })}
              />
            </div>
          }
        />
      </div>
    </div>
  );
};
