import { Tag } from "@atoms/badge/tag";
import { InputDecorationIcon } from "@atoms/input/input-decoration-icon";
import { Input } from "@atoms/input/input-text";
import Link from "@atoms/link";
import { Info, Section } from "@atoms/text";
import { Table } from "@components/table";
import { CustomersApiClient } from "@features/customers/api-client/api-client";
import { CustomerSearchType } from "@features/customers/types";
import {
  CUSTOMERS_RISKS_BACKEND_COLORS,
  extractCustomerFullName,
  CUSTOMERS_RISKS_BACKEND_LABEL,
} from "@features/customers/utils";
import { ROUTES } from "@features/routes";
import { delayRequest } from "@features/utils";
import { Transition } from "@headlessui/react";
import {
  BuildingOfficeIcon,
  MagnifyingGlassIcon,
  UserIcon,
} from "@heroicons/react/24/outline";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

export const Search = () => {
  const [focused, setFocused] = useState(false);
  const [query, setQuery] = useState("");
  const requestId = useRef<number>(0);
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState<{
    data: CustomerSearchType[];
    total: number;
    approximate?: boolean;
  }>({ data: [], total: 0 });

  const navigate = useNavigate();

  useEffect(() => {
    if (query) {
      setLoading(true);
      requestId.current++;
      const id = requestId.current;
      delayRequest("search", async () => {
        const res = await CustomersApiClient.getCustomersAdvanced(
          query
            ?.replace(/-/gm, " ")
            .split(" ")
            .filter((a) => a.trim())
            .map((q) => ({
              key: "search_aggregate",
              operations: [
                {
                  op: "regexp",
                  value: {
                    string: q, // For - in external_id
                  },
                },
              ],
            })),
          {}
        );
        if (res.data) {
          setResult(res);
        }
        if (id === requestId.current) {
          setLoading(false);
        }
      });
    }
  }, [query]);

  return (
    <div className="relative w-full text-black dark:text-white">
      <InputDecorationIcon
        className={"max-w-md w-full relative " + (focused ? "z-50" : "")}
        prefix={(p) => <MagnifyingGlassIcon {...p} />}
        input={({ className }) => (
          <Input
            size="sm"
            onFocus={() => setFocused(true)}
            onBlur={() => setTimeout(() => setFocused(false), 200)}
            onChange={(e) => setQuery(e.target.value)}
            inputClassName={"!h-8 " + className}
            placeholder="Search all customers"
            shortcut={["shift+k", "ctrl+k"]}
          />
        )}
      />
      <Transition
        show={focused}
        style={{ height: 1000000, width: 1000000, left: -500000, top: -500000 }}
        className="fixed bg-black bg-opacity-50 w-full h-full z-10"
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 "
        enterTo="transform opacity-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100"
        leaveTo="transform opacity-0 "
      />
      <Transition
        show={focused}
        className="absolute top-full left-0 bg-white dark:bg-slate-900 p-4 text-left w-full overflow-auto shadow-xl rounded-md sm:w-full z-50"
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 -translate-y-6"
        enterTo="transform opacity-100 translate-y-0"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 translate-y-0"
        leaveTo="transform opacity-0 -translate-y-6"
      >
        <Section>Search results</Section>
        <Table
          onClick={(row, e) => {
            const url = ROUTES.CustomerView.replace(":id", row.customer_id);
            if (e.shiftKey || e.ctrlKey || e.metaKey) {
              // Open in new tab
              window.open(url, "_blank");
            } else {
              navigate(url);
            }
          }}
          data={query ? result.data.slice(0, 10) : []}
          columns={[
            {
              render: (row) => row.external_id,
            },
            {
              render: (row) => (
                <div className="flex items-center">
                  {!!(row.trading_name || row.company_name) && (
                    <BuildingOfficeIcon className="h-5 w-5 inline mr-2 shrink-0" />
                  )}
                  {!(row.trading_name || row.company_name) && (
                    <UserIcon className="h-5 w-5 inline mr-2 shrink-0" />
                  )}
                  {extractCustomerFullName(row)}
                </div>
              ),
            },
            {
              render: (row) => (
                <Tag
                  noColor
                  className={
                    "mx-1 my-1 text-white bg-" +
                    (CUSTOMERS_RISKS_BACKEND_COLORS[
                      row.risk === 0 ? 1 : row.risk
                    ] || "slate-500")
                  }
                >
                  {CUSTOMERS_RISKS_BACKEND_LABEL[
                    row.risk === 0 ? 1 : row.risk
                  ] || row.risk}
                </Tag>
              ),
            },
          ]}
          loading={loading}
          showPagination={false}
        />
        {query && (
          <Info className="mt-2 block">
            Showing 10 of {result.approximate ? "about " : ""}
            {result.total} results{" - "}
            <Link
              to={ROUTES.Customers + "?search=" + encodeURIComponent(query)}
            >
              Open all customers page
            </Link>
          </Info>
        )}
      </Transition>
    </div>
  );
};
