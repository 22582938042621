import Avatar from "@atoms/avatar/avatar";
import { Tag } from "@atoms/badge/tag";
import { Button } from "@atoms/button/button";
import { PageBlock } from "@atoms/layout/page-block";
import { Base, BaseSmall, Info, InfoSmall, Section } from "@atoms/text";
import { useHasAccess } from "@features/auth/state/use-access";
import { useCustomer } from "@features/customers/state/use-customer";
import { useScenarios } from "@features/scenarios/state/use-scenarios";
import { ScoringPreferences } from "@features/scenarios/types";
import { useSession } from "@features/sessions/state/use-session";
import {
  SessionAlertStatus,
  SessionCustomerType,
  SessionDecisionType,
  SessionDetailsType,
  SessionScoringType,
} from "@features/sessions/types";
import { LABEL_TYPE_COLORS } from "@features/sessions/utils";
import { formatDate, formatDuration, formatTime } from "@features/utils/dates";
import {
  CheckIcon,
  ExclamationTriangleIcon,
  FlagIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { ArrowPathIcon, PencilSquareIcon } from "@heroicons/react/24/solid";
import { SharedProductsMenu } from "@views/client/customers/customer-details/menu";
import { capitalize } from "lodash";
import { useRecoilState } from "recoil";
import { twMerge } from "tailwind-merge";
import { SessionChangeExpirationDateModalAtom } from "./change-expiration-date-modal";
import CustomerOtherSessions from "./customer-other-sessions";
import { SessionDecisionAddModalAtom } from "./decision-add-modal";

export default function SessionSummary({
  sessionCustomer,
  documentInformation,
  ...props
}: {
  id?: string;
  sessionCustomer: SessionCustomerType;
  documentInformation?: { expiration_date: string };
  scoring: SessionScoringType;
  details: SessionDetailsType;
  decision?: SessionDecisionType;
  scoringPreferences?: ScoringPreferences;
  alertsSummary: {
    code: string;
    label: string;
    score: number;
    state: SessionAlertStatus;
  }[];
}) {
  const { customer } = useCustomer(sessionCustomer.customer_id!);
  const hasAccess = useHasAccess();
  const { rescore } = useSession(props.id || "");

  const [modal, setModal] = useRecoilState(
    SessionChangeExpirationDateModalAtom
  );

  const { scenarios } = useScenarios();
  const scenario = scenarios?.data?.find(
    (s) => s.id === props.details.scenario_id
  );

  const onRescore = async () => {
    if (props.id) {
      await rescore();
    }
  };

  const stateLabel = capitalize(props.details.state.replace("_", " "));

  const _formatDates = (date: string) => {
    return formatTime(date, undefined, { keepTime: true, keepDate: true });
  };

  const isExpired = props.details?.expiration_date
    ? new Date(props.details.expiration_date).getTime() < Date.now()
    : false;
  const expirationDate = props.details?.expiration_date
    ? formatDate(props.details?.expiration_date)
    : "never";

  return (
    <div>
      <Section className="mb-4">
        <div className="float-right">
          <SharedProductsMenu
            id={sessionCustomer.customer_id!}
            className="mt-0"
            additionalOptions={
              hasAccess("SESSION_UPDATE")
                ? [
                    {
                      label: "Rescore session",
                      onClick: onRescore,
                      icon: ({ className }) => (
                        <ArrowPathIcon className={className} />
                      ),
                    },
                  ]
                : []
            }
          />
        </div>
        Session Summary
      </Section>
      <div className="flex flex-col">
        {/* On Boarding Customer Data Info */}
        <PageBlock className="flex flex-row space-x-2" style={{ padding: 0 }}>
          <div className="grow flex flex-col space-y-2 p-4">
            <div className="flex-1 flex flex-row space-x-4">
              <Avatar
                size={28}
                avatar={sessionCustomer.avatar?.url}
                type={sessionCustomer.avatar?.type}
              />
              <div className="grow flex flex-col">
                <Section>
                  {sessionCustomer.firstname} {sessionCustomer.lastname}
                  {sessionCustomer.external_id && (
                    <Info> {sessionCustomer.external_id}</Info>
                  )}
                </Section>
                <BaseSmall className="-mt-2">
                  <Info>Date of birth </Info>{" "}
                  {
                    new Date(sessionCustomer.birthdate)
                      .toISOString()
                      .split("T")[0]
                  }
                  <Info>, Domicile </Info>{" "}
                  {customer?.details?.customer?.domicile_code ||
                    sessionCustomer.country ||
                    "-"}
                  {customer && (
                    <>
                      <Info>, Nationality </Info>{" "}
                      {customer?.details?.customer?.nationality_code || "-"}
                    </>
                  )}
                  {!!customer?.details.customer?.review_groups?.length && (
                    <Info>
                      {", "}
                      {customer?.details.customer?.review_groups?.map((r) => (
                        <Tag key={r}>{r}</Tag>
                      ))}
                    </Info>
                  )}
                </BaseSmall>
                <BaseSmall className="mt-4">
                  <div className="flex gap-2">
                    <Tag
                      className="bg-blue-500 text-white"
                      noColor
                      data-tooltip="Category"
                    >
                      {scenario?.result_label || "-"}
                    </Tag>
                    <div>
                      <Tag
                        noColor
                        className="rounded-r-none bg-blue-500 text-white"
                        data-tooltip="Scenario name"
                      >
                        {scenario?.label}
                      </Tag>
                      <Tag
                        noColor
                        className="rounded-l-none bg-slate-200 text-black dark:bg-slate-800 dark:text-white"
                      >
                        ({props.details.language})
                      </Tag>
                    </div>
                    <Tag
                      noColor
                      className={twMerge(
                        "text-white flex items-center gap-1",
                        isExpired ? "bg-red-500" : "bg-blue-500"
                      )}
                    >
                      <span>Expires {expirationDate} </span>
                      <PencilSquareIcon
                        className="h-4 w-4 cursor-pointer"
                        onClick={() =>
                          setModal({
                            ...modal,
                            date: new Date(props.details.expiration_date),
                            documentDate: documentInformation
                              ? new Date(documentInformation.expiration_date)
                              : null,
                            open: true,
                            session_id: props.details.session_id,
                          })
                        }
                      />
                    </Tag>
                  </div>
                </BaseSmall>
                {stateLabel?.toLocaleLowerCase() !== "completed" && (
                  <BaseSmall>
                    Session in state "{stateLabel}" since{" "}
                    {_formatDates(props.details.date_start)}
                  </BaseSmall>
                )}
                {stateLabel?.toLocaleLowerCase() === "completed" && (
                  <BaseSmall>
                    {stateLabel},{" "}
                    {stateLabel?.toLocaleLowerCase() === "completed" && (
                      <>
                        {_formatDates(props.details.date_end)} in{" "}
                        <b>
                          {formatDuration(
                            new Date(props.details.date_end).getTime() -
                              new Date(
                                props.details.consent_date ||
                                  props.details.date_start
                              ).getTime()
                          )}
                        </b>
                      </>
                    )}
                  </BaseSmall>
                )}
              </div>
            </div>
          </div>
          <SessionAlertsSummary
            details={props.details}
            decision={props.decision}
            alerts={props.alertsSummary}
            scoring={props.scoring as any}
            scoringPreferences={props.scoringPreferences}
          />
        </PageBlock>
        <div className="min-h-12">
          {sessionCustomer.external_id && (
            <CustomerOtherSessions
              external_id={sessionCustomer.external_id}
              current_session_id={props.details.session_id}
            />
          )}
        </div>
      </div>
    </div>
  );
}

function SessionAlertsSummary(props: {
  details: SessionDetailsType;
  decision?: SessionDecisionType;
  scoringPreferences?: ScoringPreferences;
  scoring: {
    final_result: number;
    success: boolean;
  };
  alerts: {
    code: string;
    label: string;
    score: number;
    state: SessionAlertStatus;
  }[];
}) {
  const [modal, setModal] = useRecoilState(SessionDecisionAddModalAtom);

  if (props.details.state?.toLocaleLowerCase() !== "completed") {
    return <></>;
  }

  return (
    <div className="shrink-0 flex flex-col items-start justify-end relative w-64 py-1">
      {props.alerts.map((alert) => {
        let bgColor = LABEL_TYPE_COLORS.UNDEFINED;
        switch (alert.state) {
          case SessionAlertStatus.Consider:
            bgColor = LABEL_TYPE_COLORS.NEUTRAL;
            break;
          case SessionAlertStatus.Failed:
            bgColor = LABEL_TYPE_COLORS.NEGATIVE;
            break;
          case SessionAlertStatus.Success:
            bgColor = LABEL_TYPE_COLORS.POSITIVE;
            break;
        }

        if (alert.code === "scenario") {
          const scenarioBg =
            (LABEL_TYPE_COLORS as any)[props.decision?.type as any] ||
            "bg-slate-500";
          const scenarioScoreType =
            props.scoring?.final_result >
            (props.scoringPreferences?.threshold?.fail || 0) * 100
              ? props.scoring?.final_result <
                (props.scoringPreferences?.threshold?.success || 0) * 100
                ? "NEUTRAL"
                : "POSITIVE"
              : "NEGATIVE";
          return (
            <div key={alert.code} className={`w-full absolute top-full`}>
              <div
                className={`relative w-full p-1 flex flex-row space-x-2 items-start justify-start text-white rounded-b-lg ${scenarioBg} border-t border-slate-300`}
              >
                <Base
                  noColor
                  className={twMerge(
                    "px-1",
                    props.decision?.type !== scenarioScoreType && "line-through"
                  )}
                >
                  <InfoSmall noColor>System score</InfoSmall>
                  <br />
                  {Math.floor(props.scoring?.final_result || 0) ?? "-"} %
                </Base>

                <div className="grow" />

                <div className="px-1">
                  <InfoSmall noColor>Decision</InfoSmall>
                  <br />
                  {props.decision ? (
                    <Tag
                      noColor
                      className={
                        "text-white border border-white " +
                        (props.decision.type === "NEGATIVE"
                          ? "bg-red-500"
                          : props.decision.type === "POSITIVE"
                          ? "bg-green-500"
                          : "bg-yellow-500")
                      }
                    >
                      {props.decision.label}
                    </Tag>
                  ) : (
                    <Button
                      theme="secondary"
                      className="flex flex-row space-x-2"
                      size="sm"
                      onClick={() =>
                        setModal({
                          ...modal,
                          status: props.decision?.status_id || 0,
                          open: true,
                          session_id: props.details.session_id,
                        })
                      }
                      shortcut={["u"]}
                    >
                      <FlagIcon className="w-5 h-5" />
                      <BaseSmall noColor>Take Decision</BaseSmall>
                    </Button>
                  )}
                </div>
              </div>
            </div>
          );
        }

        return (
          <BaseSmall
            key={alert.code}
            className={`py-1 flex flex-row space-x-2 items-center justify-start`}
          >
            <div
              className={`w-5 h-5 p-1 flex text-white items-center justify-center rounded-full ${bgColor}`}
            >
              {alert.state === SessionAlertStatus.Failed ? (
                <XMarkIcon className="w-4 h-4" />
              ) : alert.state === SessionAlertStatus.Success ? (
                <CheckIcon className="w-4 h-4" />
              ) : (
                <ExclamationTriangleIcon className="w-4 h-4" />
              )}
            </div>
            <BaseSmall>{alert.label}</BaseSmall>
          </BaseSmall>
        );
      })}
    </div>
  );
}
