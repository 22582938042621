import { Base, InfoSmall } from "@atoms/text";
import Env from "@config/environment";
import { SessionQuestionInformationTry } from "@features/sessions/types";

type QuestionContentProps = {
  answer?: SessionQuestionInformationTry;
};

export function QuestionContent({ answer }: QuestionContentProps) {
  return (
    <div className="flex flex-col">
      {answer && (
        <div className="flex space-x-4">
          {answer.video && (
            <div>
              <video
                src={Env.server + answer.video.url}
                controls
                preload="auto"
                className="bg-black h-96 w-auto object-cover rounded-md border border-slate-300"
              />
            </div>
          )}
          <div>
            <InfoSmall>Customer answer (speech recognition) : </InfoSmall>
            <br />
            <Base>{answer.answer}</Base>
          </div>
        </div>
      )}
    </div>
  );
}
