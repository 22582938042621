import { atom, atomFamily } from "recoil";
import {
  KytCurrenciesWithID,
  KytGraph,
  KytNode,
  KytPostHocPattern,
  KytTransaction,
  KytTransactionHistory,
  KytTransactionsRequestOptions,
} from "../types";
import { RestSearchRequest } from "@components/search-bar/utils/types";

export const TransactionFiltersAtom = atom<KytTransactionsRequestOptions>({
  key: "TransactionFiltersAtom",
  effects_UNSTABLE: [
    ({ onSet }) => {
      onSet((newValue) => {
        const url = new URL(window.location.href);
        url.searchParams.set("q", JSON.stringify(newValue));
        window.history.replaceState({}, "", url.toString());
        localStorage.setItem(
          "saved.transactions_filters",
          JSON.stringify(newValue)
        );
      });
    },
  ],
  default: (() => {
    let saved = {};
    try {
      saved = JSON.parse(
        (new URLSearchParams(window.location.search).get("q") ||
          localStorage.getItem("saved.transactions_filters")) ??
          "{}"
      );
    } catch (e) {
      // No-op
    }

    saved = {
      on_page: 1,
      per_page: 10,
      ...saved,
    };
    return saved as KytTransactionsRequestOptions;
  })(),
});

export const TransactionsListAtom = atom<{
  data: KytTransaction[];
  total: number;
}>({
  key: "TransactionsListAtom",
  default: { data: [], total: 0 },
});

export const PostHocPatternsListAtom = atom<KytPostHocPattern[]>({
  key: "PostHocPatternsListAtom",
  default: [],
});

export const TransactionAtom = atomFamily<KytTransactionHistory | null, string>(
  {
    key: "KytTransaction",
    default: () => null,
  }
);

export const ExploreKytGraphAtom = atom<KytGraph | null>({
  key: "ExploreKytGraphAtom",
  default: null,
});
export const ExploreKytTransactionsAtom = atom<{
  transactions: KytTransaction[];
  total: number;
} | null>({
  key: "ExploreKytTransactionsAtom",
  default: null,
});

export const ExploreKytLayoutAtom = atom<{
  graph: boolean;
  relations: boolean;
  transactions: boolean;
}>({
  key: "ExploreKytLayoutAtom",
  default: {
    graph: false,
    relations: false,
    transactions: false,
  },
});

export const FocusedNodeAtom = atom<KytNode | null>({
  key: "focusedNode",
  default: null,
});

export const GraphQueryAtom = atom<string | null>({
  key: "GraphQueryAtom",
  default: null,
});

export const CurrenciesListAtom = atom<{
  data: KytCurrenciesWithID[];
}>({
  key: "CurrenciesListAtom",
  default: { data: [] },
  // default: { data: [], total: 0 },
});

export const TransactionsQueryInputAtom = atom<RestSearchRequest>(
  (() => {
    const localOptions = localStorage.getItem(
      "advancedquery.transactions.options"
    );
    return {
      key: "TransactionsQueryInputAtom",
      default: {
        query: [],
        options: localOptions
          ? JSON.parse(localOptions)
          : {
              limit: 10,
              offset: 0,
              order_by: "date",
              order_way: "ASC",
            },
      },
      effects_UNSTABLE: [
        ({ onSet }) => {
          onSet((newValue) => {
            localStorage.setItem(
              "advancedquery.transactions.options",
              JSON.stringify(newValue.options)
            );
          });
        },
      ],
    };
  })()
);
