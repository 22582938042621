import countries from "@assets/countries.json";
import Avatar from "@atoms/avatar/avatar";
import { Tag } from "@atoms/badge/tag";
import { PageBlock } from "@atoms/layout/page-block";
import { Base, Info, InfoSmall, Section } from "@atoms/text";
import { useCustomFields } from "@features/custom-fields/state/use-custom-fields";
import { useCustomerStates } from "@features/customers/state/user-customer-states";
import { CustomerAllDetailType } from "@features/customers/types";
import {
  CUSTOMERS_RISKS_BACKEND_COLORS,
  CUSTOMERS_RISKS_BACKEND_LABEL,
  extractCustomerFullName,
  STATES_COLORS,
} from "@features/customers/utils";
import { useDashboard } from "@features/dashboard/state/hooks";
import { useSessions } from "@features/sessions/state/use-sessions";
import {
  SessionCustomerAvatar,
  SessionsRequestOptions,
} from "@features/sessions/types";
import { useControlledEffect } from "@features/utils";
import { formatTime } from "@features/utils/dates";
import { BuildingOfficeIcon, UserIcon } from "@heroicons/react/24/outline";
import { useCallback, useState } from "react";
import { twMerge } from "tailwind-merge";
import { formatFieldValue } from "./custom-fields";
import { useOlkyPassSpecifics } from "./olkypass";

export default function CustomerSummary({
  customer,
}: {
  customer: CustomerAllDetailType;
}) {
  const customFields = customer?.details.custom_fields ?? [];
  const customerDetails = customer.details.customer;
  const { risk } = useDashboard();
  const { states } = useCustomerStates();
  const { fields } = useCustomFields();
  const { loading, fetchAvatarSessionWithExternalID } = useSessions();
  const [avatar, setAvatar] = useState<SessionCustomerAvatar>();

  const findLatestSessionWithFace = useCallback(async () => {
    const avatar = await fetchAvatarSessionWithExternalID({
      external_id: customer?.details.customer.external_id,
    } as SessionsRequestOptions);
    setAvatar(avatar);
  }, [customer, fetchAvatarSessionWithExternalID]);

  useControlledEffect(() => {
    findLatestSessionWithFace();
  }, [customer]);

  const lastRiskDate = Math.max(
    // Global scan end-date
    new Date(risk?.last_risk_scoring_info?.end_date || 0).getTime(),
    // Customer last updated
    new Date(customerDetails.created_at || 0).getTime()
  );

  /**
   * This is a special case for OLKY, only triggered when the 'olkypass_statut' field is present
   */
  const olkypass = useOlkyPassSpecifics(
    customer?.details?.customer?.id,
    customFields
  );

  return (
    <>
      <Section className="mt-4">Customer summary</Section>
      <div className="mt-4 flex flex-row">
        <PageBlock className="grow w-1/2">
          <div className="flex flex-row w-full py-2 items-center">
            {customerDetails.customer_type !== "3" && avatar ? (
              <Avatar
                size={24}
                className="mr-4 shrink-0"
                key={`profile_picture_${customerDetails.external_id}`}
                avatar={avatar.url}
                type={avatar.type}
                loading={loading}
              />
            ) : (
              <Avatar
                size={24}
                className="mr-4 shrink-0"
                key={`profile_picture_${customerDetails.external_id}`}
                loading={loading}
              />
            )}
            <div className="grow">
              <Section className="flex flex-row items-center">
                {customerDetails.customer_type !== "3" ? (
                  <UserIcon className="h-5 w-5 inline mr-2 shrink-0" />
                ) : (
                  <BuildingOfficeIcon className="h-5 w-5 inline mr-2 shrink-0" />
                )}
                {extractCustomerFullName(customerDetails)}
              </Section>

              <div className="-mt-1">
                <Base className="mr-4">
                  <Info>ID</Info> {customerDetails?.external_id}
                </Base>
                {customerDetails?.registration_number && (
                  <Base className="mr-4">
                    <Info>Registration Number</Info>{" "}
                    {customerDetails?.registration_number}
                  </Base>
                )}
              </div>

              {customerDetails?.date_of_birth && (
                <Base className="mr-4">
                  <Info>Date of birth</Info>{" "}
                  {(customerDetails?.date_of_birth
                    ? new Date(customerDetails?.date_of_birth)
                        .toISOString()
                        .split("T")[0]
                    : null) || "Not defined"}
                </Base>
              )}
              {customerDetails?.nationality_codes.length > 0 && (
                <Base className="mr-4">
                  <Info>
                    {"Nationalit" +
                      (customerDetails?.nationality_codes.length > 1
                        ? "ies"
                        : "y")}
                  </Info>{" "}
                  {customerDetails?.nationality_codes.map((code) => (
                    <>
                      {countries.find((c) => c["alpha-3"] === code)?.name ||
                        "Invalid"}{" "}
                      <Info>({code})</Info>{" "}
                    </>
                  ))}
                </Base>
              )}
              {customerDetails?.domicile_codes.length > 0 && (
                <Base className="mr-4">
                  <Info>
                    {"Domicil" +
                      (customerDetails?.domicile_codes.length > 1 ? "es" : "e")}
                  </Info>{" "}
                  {customerDetails?.domicile_codes.map((code) => (
                    <>
                      {countries.find((c) => c["alpha-3"] === code)?.name ||
                        "Invalid"}{" "}
                      <Info>({code})</Info>{" "}
                    </>
                  ))}
                </Base>
              )}

              <div>
                {customFields
                  .filter((cf) =>
                    fields
                      .filter((f) => f.field_id === cf.id)
                      .map((f) => f.format.related_products)
                      .flat()
                      .includes("header")
                  )
                  .map((f) => (
                    <Base key={f.id} className="mr-4">
                      <Info>
                        {fields.find((a) => a.field_id === f.id)?.header_name}
                      </Info>{" "}
                      {formatFieldValue(
                        f.value,
                        fields.find((a) => a.field_id === f.id)?.format
                      )}
                    </Base>
                  ))}
              </div>
            </div>
            <div
              className={twMerge("text-left", olkypass.use ? "w-2/5" : "w-1/3")}
            >
              <div className="flex flex-row space-x-px">
                {olkypass.use && (
                  <StatusCard
                    color={olkypass.color || "slate-500"}
                    label={"Level"}
                    status={olkypass.state || "Unknown"}
                    updatedAt={new Date(olkypass.updated || 0).getTime()}
                    className="rounded-r-none"
                  />
                )}
                <StatusCard
                  color={
                    STATES_COLORS[
                      customerDetails.disabled
                        ? "disabled"
                        : `${customer.details.edd_status.edd_state}`
                    ] || "slate-500"
                  }
                  label={"Status"}
                  status={
                    states[customer.details.edd_status.edd_state] || "Unknown"
                  }
                  updatedAt={new Date(
                    customer.details.edd_status.created_at || 0
                  ).getTime()}
                  className={olkypass.use ? "rounded-none" : "rounded-r-none"}
                />
                <StatusCard
                  color={
                    CUSTOMERS_RISKS_BACKEND_COLORS[
                      `${customer.details.overall_risk}`
                    ] || "slate-500"
                  }
                  label={"Risk"}
                  status={
                    CUSTOMERS_RISKS_BACKEND_LABEL[
                      customer.details.overall_risk
                    ] || "Undefined"
                  }
                  updatedAt={new Date(lastRiskDate).getTime() || 0}
                  className="rounded-l-none"
                />
              </div>
              <div className="mt-2 block space-x-1">
                <div className="inline-block">
                  <InfoSmall>
                    Customer created{" "}
                    <b>
                      {formatTime(
                        customer.edd_history.edd_revisions[
                          customer.edd_history.edd_revisions.length - 1
                        ]?.created_at || customer?.details?.customer?.created_at
                      )}
                    </b>
                  </InfoSmall>
                </div>
                <div className="inline-block">
                  <InfoSmall>• Review groups</InfoSmall>{" "}
                  {customer?.details?.customer?.review_groups?.map(
                    (group, i) => (
                      <Tag key={i} className="mr-1">
                        {group}
                      </Tag>
                    )
                  )}
                </div>
              </div>
            </div>
          </div>
        </PageBlock>
      </div>
    </>
  );
}

const StatusCard = ({
  updatedAt,
  color,
  status,
  label,
  className,
}: {
  updatedAt: number;
  color: string;
  label: string;
  status: string;
  className?: string;
}) => {
  return (
    <div
      className={twMerge(
        "rounded",
        "grid grid-cols-1 grid-rows-2 p-2 gap-y-1 bg-opacity-10 grow items-center",
        "bg-" + color,
        className
      )}
    >
      <div className="flex items-center justify-between">
        <Tag noColor className={"text-white !px-3 !py-1 text-md bg-" + color}>
          <InfoSmall noColor>{label}</InfoSmall>{" "}
          <span className="capitalize">{status}</span>
        </Tag>
      </div>
      <InfoSmall data-tooltip={new Date(updatedAt).toLocaleString()}>
        Updated{" "}
        {new Date(updatedAt).getTime() > 0
          ? new Date(updatedAt).toISOString().split("T")[0]
          : "-"}
      </InfoSmall>
    </div>
  );
};
