import { useCustomFields } from "@features/custom-fields/state/use-custom-fields";
import { CustomersApiClient } from "@features/customers/api-client/api-client";
import { useGlobalEffect } from "@features/utils";
import _ from "lodash";
import { useQuery } from "react-query";
import { useGetMatrixLabelColor } from "./custom-fields";

export const useOlkyPassSpecifics = (
  id: string,
  customFields: {
    fieldtype: number;
    name: string;
    value: any;
    id: number;
  }[]
) => {
  const { fields } = useCustomFields();

  const customFieldsWithConfig = customFields.map((cf) => ({
    ...cf,
    config: fields.find((f) => f.field_id === cf.id),
  }));

  const olkypassFieldLabel = "olkypass_statut";
  const olkypassField = customFieldsWithConfig.find(
    (f) => f.config?.label === olkypassFieldLabel
  );

  const lastOlkyPassUpdate = useQuery({
    queryKey: ["olkypass-history", id],
    queryFn: async () => {
      if (!olkypassField) return null;
      console.log("Fetching olkypass history", id);
      const resUnsorted = await CustomersApiClient.getRevisions(id);
      const res = _.sortBy(resUnsorted, (a) =>
        new Date(a.created_at).getTime()
      );
      let lastUpdated = res[0].created_at;
      for (let i = 1; i < res.length; i++) {
        if (
          res[i].custom_fields[olkypassFieldLabel] !==
          res[i - 1]?.custom_fields?.[olkypassFieldLabel]
        ) {
          lastUpdated = res[i].created_at;
        }
      }
      return new Date(lastUpdated).getTime();
    },
  });
  useGlobalEffect(
    "olkypass-history-" + id,
    () => {
      if (olkypassField) lastOlkyPassUpdate.refetch();
    },
    [olkypassField]
  );

  const { label, color } = useGetMatrixLabelColor(
    olkypassField?.config?.format?.type?.split("matrix_")[1] || ""
  )(olkypassField?.value);
  return {
    use: olkypassField !== undefined,
    color: color,
    state: label,
    updated: new Date(lastOlkyPassUpdate?.data || 0),
  };
};
