import Avatar from "@atoms/avatar/avatar";
import { Button } from "@atoms/button/button";
import SelectMultiple from "@atoms/input/input-select-multiple";
import Link from "@atoms/link";
import { Multiselect } from "@atoms/multiselect-react-dropdown/multiselect/multiselect.component";
import { Base, BaseSmall, InfoSmall, Section } from "@atoms/text";
import { useAgents } from "@features/agents/state/use-agents";
import { useAssignment } from "@features/assignments/state/use-assignment";
import { useAuth } from "@features/auth/state/hooks";
import { TrashIcon } from "@heroicons/react/24/outline";
import _ from "lodash";
import { useCallback, useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import { AssigneeTag } from "./tag";

export default function Assignments(props: {
  type:
    | "customer"
    | "alert"
    | "session"
    | "thread"
    | "transaction"
    | "pressreport";
  id: string;
  groups?: string[];
  readonly?: boolean;
  inline?: boolean;
}) {
  const { assignment, loading, assignMembers } = useAssignment(
    props.type,
    props.id
  );
  const { user, clientId } = useAuth();
  const { getAgentForClient } = useAgents();
  const agents = getAgentForClient();
  const [selectedAgents, setSelectedAgents] = useState<string[]>([]);
  const [editing, setEditing] = useState(false);
  const selectRef = useRef<Multiselect>(null);

  useEffect(() => {
    if (assignment?.members) {
      setSelectedAgents(
        assignment?.members.map((member) => member.id.toString())
      );
    }
  }, [assignment]);

  const save = useCallback(
    async (ids?: string[]) => {
      if (ids) {
        setSelectedAgents(ids);
      } else {
        ids = selectedAgents;
      }
      // Save new members assignement
      await assignMembers(_.uniq(ids.map((agentId) => parseInt(agentId))));
    },
    [assignMembers, selectedAgents]
  );

  const _initAgentsAvailables = () => {
    // need agents actives with current client active.
    let agentsActives = agents.filter(
      (agent) =>
        agent.active &&
        agent.clients.filter(
          (client) => client.active && client.id === clientId
        ).length > 0
    );

    // Si le customer/session/alerte n'a pas de review group défini, alors tous les agents peuvent être sélectionés
    if (props.groups === undefined || props.groups.length === 0) {
      return agentsActives;
    }

    // Sinon on n'affiche que:
    agentsActives = agentsActives.filter(
      (agent) =>
        // Les agents qui n'ont aucun review_group défini (car ils ont accès à tout)
        agent.clients.filter(
          (client) =>
            client.id === clientId && client.review_groups?.includes("*")
        ).length > 0 ||
        // Les agents qui dont l'intersection de leur review groups avec ceux du customer/session/alerte est non vide
        agent.clients.filter(
          (client) =>
            (client.review_groups || []).filter(
              (clientReview) =>
                client.id === clientId && props.groups?.includes(clientReview)
            ).length > 0
        ).length > 0
    );

    return agentsActives;
  };

  // assignements Members need to be deleted from agents list
  const agentsActives = _initAgentsAvailables();
  const membersId: string[] =
    assignment?.members?.map((member) => member.id.toString()) || [];

  return (
    <div
      className={
        "relative " +
        (props.inline ? "flex flex-row space-x-2 items-center" : "")
      }
    >
      <div
        className={
          "absolute top-0 left-0 w-full z-20 " +
          (editing ? "" : "cursor-pointer opacity-0 pointer-events-none")
        }
      >
        <SelectMultiple
          multiselectRef={selectRef}
          disabled={props.readonly}
          value={selectedAgents}
          onChange={(e) => {
            setSelectedAgents(e);
          }}
          onBlur={() => {
            if (editing && selectedAgents?.length !== undefined) {
              setEditing(false);
              if (
                !_.isEqual(
                  _.uniq(
                    selectedAgents.map((agentId) => parseInt(agentId))
                  ).sort(),
                  _.uniq(
                    (assignment?.members || []).map((member) => member.id)
                  ).sort()
                )
              ) {
                save();
              }
            }
          }}
          onFocus={() => setEditing(true)}
          options={_.sortBy(agentsActives, (a) => {
            //Sort by me first, then selected, then alphabetical order
            if (a?.id === user?.id) {
              return "0";
            }
            if (membersId.includes(a?.id?.toString())) {
              return "1" + a;
            }
            return a;
          }).map((agent) => ({
            label: agent.name,
            value: agent.id.toString(), //This is a *member* id, not an agent id
          }))}
        />
      </div>

      {props.inline && (
        <div className="mr-2 inline-block">
          {loading && !assignment?.members?.length && (
            <BaseSmall>Loading assignees...</BaseSmall>
          )}
          {!loading && !assignment?.members?.length && (
            <BaseSmall>
              No assignee{" "}
              {!props.readonly && (
                <>
                  {" "}
                  -{" "}
                  <Link
                    onClick={() => {
                      save([user!.id.toString()]);
                    }}
                    shortcut={["m"]}
                  >
                    Assign to me
                  </Link>
                </>
              )}
            </BaseSmall>
          )}
          {assignment && assignment.members !== null && (
            <div className="flex flex-row space-x-2 items-center">
              <BaseSmall>Assigned to </BaseSmall>
              <AssigneeTag className="-mt-1" type={props.type} id={props.id} />
            </div>
          )}
        </div>
      )}

      <Button
        className="float-right"
        size="sm"
        theme="default"
        onClick={() => {
          setEditing(true);
          selectRef.current?.focus();
        }}
        shortcut={["a"]}
      >
        Edit
      </Button>

      {!props.inline && (
        <>
          <Section className="mt-4">Assignees</Section>

          {loading && !assignment?.members?.length && (
            <BaseSmall>Loading...</BaseSmall>
          )}
          {!loading && !assignment?.members?.length && (
            <BaseSmall>
              No assignee{" "}
              {!props.readonly && (
                <>
                  {" "}
                  -{" "}
                  <Link
                    onClick={() => {
                      save([user!.id.toString()]);
                    }}
                    shortcut={["m"]}
                  >
                    Assign to me
                  </Link>
                </>
              )}
            </BaseSmall>
          )}

          {assignment && assignment.members !== null && (
            <div className={loading ? "opacity-75 pointer-events-none" : ""}>
              {_.sortBy(assignment.members, (a) => {
                //Return me first then by alphabetical order
                if (a.id === user?.id) {
                  return "0";
                }
                return a.name;
              }).map((member) => (
                <div
                  key={member.id}
                  className={
                    "group flex flex-row -ml-1 items-center border border-transparent hover:border-slate-200 dark:hover:border-slate-600 hover:bg-white dark:hover:bg-slate-900 rounded p-1 " +
                    (user!.id.toString() === member!.id.toString()
                      ? "bg-yellow-100 dark:bg-yellow-900 border-yellow-500"
                      : "")
                  }
                >
                  <Avatar
                    size={6}
                    avatar={member?.avatar}
                    fallback={member?.name}
                    className="mr-2 shrink-0"
                  />
                  <Base
                    noColor
                    className="font-semibold overflow-hidden whitespace-nowrap text-ellipsis"
                  >
                    {member?.name}
                    {user!.id.toString() === member!.id.toString() && (
                      <> (Me)</>
                    )}{" "}
                    <InfoSmall className="font-semibold grow">
                      {member?.email_login}
                    </InfoSmall>
                  </Base>
                  {!props.readonly && (
                    <TrashIcon
                      color="red"
                      onClick={async () => {
                        // delete member
                        try {
                          await save(
                            selectedAgents.filter(
                              (a) => a !== member!.id.toString()
                            )
                          );
                        } catch (e) {
                          toast.error("Error while deleting member");
                        }
                      }}
                      className="shrink-0 w-4 h-4 mr-1 ml-auto cursor-pointer group-hover:opacity-100 opacity-0"
                    />
                  )}
                </div>
              ))}
            </div>
          )}
        </>
      )}
    </div>
  );
}
