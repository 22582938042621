import Env from "@config/environment";
import { QuestionType } from "@features/scenarios/types";
import { capitalize } from "lodash";
import {
  AlertType,
  AlertTypes,
  SessionAlertStatus,
  SessionAlertType,
  SessionControlResult,
  SessionControlType,
  SessionCustomerAvatar,
  SessionCustomerType,
  SessionDocument,
  SessionDocumentType,
  SessionListType,
  SessionQuestionInformation,
  SessionQuestionInstanceType,
  SessionStatus,
  SessionType,
} from "./types";

export const SESSIONS_LANGUAGES: {
  [key: string]: string;
} = {
  "ar-AE": "Arabic",
  "cs-CZ": "Czech",
  "da-DK": "Danish",
  "de-DE": "Deutsch",
  "en-US": "English",
  "es-ES": "Español",
  "fi-FI": "Finnish",
  "fr-FR": "Français",
  "id-ID": "Indonesian",
  "it-IT": "Italian",
  "nl-NL": "Nederlands",
  "ro-RO": "Romanian",
  "ru-RU": "Russian",
  "sv-SE": "Swedish",
  "tr-TR": "Turkish",
};

export const LABEL_TYPE_COLORS = {
  POSITIVE: "bg-green-500",
  NEGATIVE: "bg-red-500",
  NEUTRAL: "bg-orange-500",
  UNDEFINED: "bg-gray-500",
};

export const LABEL_TYPE_COLORS_HEX = {
  POSITIVE: "#10B981",
  NEGATIVE: "#EF4444",
  NEUTRAL: "#FCD34D",
  UNDEFINED: "#6B7280",
};

export const REVIEW_STATUS = ["PASS", "REJECTED"];

export enum SessionWarnings {
  ip_mismatch = 1,
  missing_id_checks,
  watch_list,
  face_fail,
  id_fail,
}

export const extractCustomerSessionFullName = (item: SessionListType) => {
  return [item.customer_firstname, item.customer_lastname]
    .filter((a) => a)
    .join(" ");
};

export const extratCustomerSessionAddress = (item: SessionCustomerType) => {
  return [item.address, item.city, item.zip].filter((a) => a).join(", ");
};

// HANDLING SESSION TRANSFORMATIONS FOR UI
export const moveIdentityAttachementToFaceQuestion = (session: SessionType) => {
  if (session.questions.length === 0) {
    return;
  }

  const identityQuestion = session.questions.find(
    (question) => question.type === QuestionType.Identity
  );
  if (!identityQuestion) {
    return;
  }
  const identityInstances =
    identityQuestion.instances?.filter((instance) => instance.answers) ?? [];
  if (identityInstances.length === 0) {
    return;
  }

  const lastIdentityQuestion = identityInstances[identityInstances.length - 1];
  const identityFaceImage = lastIdentityQuestion.answers?.attachment_urls?.find(
    (attachment) => attachment.type === "identity_face"
  );

  if (identityFaceImage) {
    lastIdentityQuestion.answers["identity_face"] = identityFaceImage;
  }
};

export const getVersion = (session: SessionType) => {
  const faceQuestion = session.questions?.find(
    (question) => question.type === QuestionType.Face
  );
  if (!faceQuestion) {
    return 1;
  }
  const instances = faceQuestion.instances;
  if (!instances || instances.length === 0) {
    return 1;
  }
  const version = instances[0].answers?.face_contents?.version || 1;

  return version;
};

export const getSessionAvatar = (session: SessionType) => {
  const avatar: SessionCustomerAvatar = { type: "image", url: "" };
  const faceQuestion = session.questions.find(
    (question) => question.type === QuestionType.Face
  );
  const identityQuestion = session.questions.find(
    (question) => question.type === QuestionType.Identity
  );

  if (session.version === 2) {
    avatar.type = "video";
    if (faceQuestion?.instances?.length ?? 0 > 0) {
      const lastInstance = faceQuestion?.instances
        .filter((instance) => instance.answers != null)
        .at(-1);
      if (lastInstance && lastInstance.answers.videos.length > 0) {
        avatar.url = `${Env.server}${lastInstance.answers.videos[0].url}`;
        return avatar;
      }
    }
  }
  avatar.type = "image";
  let faceImage = null;
  if (faceQuestion?.instances?.length ?? 0 > 0) {
    faceImage = faceQuestion?.instances[
      faceQuestion.instances.length - 1
    ].answers?.attachment_urls?.find(
      (attachment) => attachment.type === "face"
    );
    faceImage = faceQuestion?.instances[
      faceQuestion.instances.length - 1
    ].answers?.attachment_urls?.find(
      (attachment) => attachment.type === "face"
    );
  }
  let identityImage = null;
  if (identityQuestion?.instances?.length ?? 0 > 0) {
    identityImage = identityQuestion?.instances[
      identityQuestion.instances.length - 1
    ]?.answers?.attachment_urls?.find(
      (attachment) => attachment.type === "identity_face"
    );
    identityImage = identityQuestion?.instances[
      identityQuestion.instances.length - 1
    ]?.answers?.attachment_urls?.find(
      (attachment) => attachment.type === "identity_face"
    );
  }

  if (
    !session.onboarding_customer ||
    (faceImage === null && identityImage === null)
  ) {
    return;
  }
  avatar.url = `${Env.server}/api/v1/downloads/${
    faceImage?.id || identityImage?.id || ""
  }`;
  return avatar;
};

export const setSessionAlerts = (session: SessionType) => {
  const alerts = AlertTypes;
  const questionTypes: string[] = session.questions.map(
    (question) => question.type
  );
  const indicators = [];
  if (session.scoring_preferences_used.captcha.enabled) {
    indicators.push(AlertType.Captcha);
  }
  if (session.scoring_preferences_used.geolocation.enabled) {
    indicators.push(AlertType.Location);
  }
  if (session.scoring_preferences_used.watchlist.enabled) {
    indicators.push(AlertType.Watchlist);
  }
  const types = [AlertType.Scenario, ...indicators, ...questionTypes];
  session.alerts = alerts
    .filter((alert) => {
      return types.includes(alert as string);
    })
    .map((alert) => {
      switch (alert) {
        case AlertType.Face:
          return _generateSessionFaceAlert(session);
        case AlertType.Identity:
          return _generateSessionIdentityAlert(session);
        case AlertType.Location:
          return _generateSessionLocationAlert(session);
        case AlertType.Watchlist:
          return _generateSessionWatchListAlert(session);
        case AlertType.Captcha:
          return _generateSessionCaptchaAlert(session);
        case AlertType.Scenario:
          return _generateSessionScenarioAlert(session);
        case AlertType.Information:
          return _generateSessionInformationAlert(session);
        case AlertType.FileUpload:
          return _generateSessionFileUploadAlert(session);
        default:
          return {
            code: alert,
            label: capitalize(alert),
            eliminatory: false,
            score: -1,
            state: SessionAlertStatus.Undefined,
          };
      }
    });
  session.documents = _getSessionAllDocuments(session.questions) || [];
};

const _generateSessionInformationAlert = (
  session: SessionType
): SessionAlertType => {
  const alert: SessionAlertType = {
    code: AlertType.Information,
    label: "Identity",
    eliminatory: false,
    state: SessionAlertStatus.Undefined,
  };
  const questions = session.questions.filter(
    (question) => question.type === QuestionType.Information
  );
  questions.forEach((question) => {
    if (!alert.informations) {
      alert.informations = [];
    }
    const instances = (question.instances || []).filter(
      (instance) => !!instance.answers && instance.answers.information_data
    );
    if (!instances || instances.length === 0) {
      return;
    }
    const information: SessionQuestionInformation = {
      should_not_contain: question.data.should_not_contain,
      possible_answers: question.data.possible_answers,
      question_name: question.label,
      question_content:
        question.description_localized[
          Object.keys(question.description_localized)[0]
        ],
      coefficient: question.coefficient,
      tries: instances.map((instance) => ({
        answer: instance.answers.information_data.answer,
        result: instance.answers.eval_result,
        video: instance.answers.videos[0],
      })),
    };
    const lastInstance = instances.at(-1);
    if (
      lastInstance &&
      lastInstance.answers.eval_result &&
      alert.state === SessionAlertStatus.Undefined
    ) {
      alert.state = SessionAlertStatus.Success;
    } else {
      alert.state = SessionAlertStatus.Failed;
    }
    alert.informations.push(information);
  });
  return alert;
};

const _generateSessionFileUploadAlert = (
  session: SessionType
): SessionAlertType => {
  const alert: SessionAlertType = {
    code: AlertType.FileUpload,
    label: "Document upload",
    eliminatory: false,
    state: SessionAlertStatus.Undefined,
  };

  const fileUploadQuestions = session.questions?.filter(
    (question) => question.type === QuestionType.File_Upload
  );

  let instances: SessionQuestionInstanceType[] | [] = [];

  fileUploadQuestions.forEach((question) => {
    if (!alert.uploads) {
      alert.uploads = [];
    }
    if (question.instances?.length ?? 0 > 0) {
      instances =
        question.instances.filter((instance) => !!instance.answers) || null;
    }
    (question.instances || []).forEach((instance, index) => {
      if (instance.answers?.attachment_urls?.length) {
        alert.uploads!.push({
          id: instance.answers.attachment_urls[0].id,
          is_last_try: index === question.instances.length - 1,
          try_index: index,
          url:
            instance.answers.attachment_urls[0].type ===
            SessionDocumentType.Upload
              ? "/api/v1/downloads/" + instance.answers.attachment_urls[0].id
              : "/api/v1/downloads/pdf?ids=" +
                instance.answers.attachment_urls.map((a) => a.id).join(","),
          name: question.description_localized[
            Object.keys(question.description_localized)[0]
          ],
          type: question.type,
          file_name: instance.answers.file_upload_content?.file_name,
          file_size: instance.answers.file_upload_content?.file_size,
          file_type:
            instance.answers.file_upload_content?.file_type ||
            instance.answers.attachment_urls[0].type,
          previews:
            instance.answers.attachment_urls[0].type ===
            SessionDocumentType.Upload
              ? []
              : instance.answers.attachment_urls.map(
                  (a) => "/api/v1/downloads/" + a.id
                ),
        });
      }
    });
  });
  alert.questions = instances ?? [];

  return alert;
};

const _getSessionAllDocuments = (
  questions: SessionType["questions"]
): SessionDocument[] => {
  const uploads: SessionDocument[] = [];

  questions.forEach((question) => {
    (question.instances || []).forEach((instance, index) => {
      if (instance.answers?.attachment_urls?.length) {
        uploads!.push({
          id: instance.answers.attachment_urls[0].id,
          is_last_try: index === question.instances.length - 1,
          try_index: index,
          url:
            instance.answers.attachment_urls[0].type ===
            SessionDocumentType.Upload
              ? "/api/v1/downloads/" + instance.answers.attachment_urls[0].id
              : "/api/v1/downloads/pdf?ids=" +
                instance.answers.attachment_urls.map((a) => a.id).join(","),
          name: question.description_localized[
            Object.keys(question.description_localized)[0]
          ],
          type: question.type,
          file_name: instance.answers.file_upload_content?.file_name,
          file_size: instance.answers.file_upload_content?.file_size,
          file_type:
            instance.answers.file_upload_content?.file_type ||
            instance.answers.attachment_urls[0].type,
          previews:
            instance.answers.attachment_urls[0].type ===
            SessionDocumentType.Upload
              ? []
              : instance.answers.attachment_urls.map(
                  (a) => "/api/v1/downloads/" + a.id
                ),
        });
      }
    });
  });

  return uploads;
};

const sessionStatusToAlertStatus = (status: SessionStatus) => {
  const dict = {
    [SessionStatus.Undefined]: SessionAlertStatus.Undefined,
    [SessionStatus.Failed]: SessionAlertStatus.Failed,
    [SessionStatus.Pending]: SessionAlertStatus.Consider,
    [SessionStatus.Success]: SessionAlertStatus.Success,
  };
  return dict[status];
};

export const questionScoreToAlertStatus = (
  session: SessionType,
  questionId: number
) => {
  const question = session.questions.find(
    (question) => question.id === questionId
  );
  const lastInstance = question?.instances?.at(-1);
  if (lastInstance?.status) {
    return sessionStatusToAlertStatus(lastInstance.status);
  }
  if (!lastInstance) {
    return SessionAlertStatus.Undefined;
  }

  const scoring = session.scoring?.responses
    .filter((response) => response.id === lastInstance.id)
    .at(-1);
  if (
    !scoring ||
    scoring.score < session.scoring_preferences_used.threshold.success
  ) {
    return SessionAlertStatus.Failed;
  }

  return SessionAlertStatus.Success;
};

const _generateSessionFaceAlert = (session: SessionType): SessionAlertType => {
  const alert: SessionAlertType = {
    code: "face",
    label: "Face",
    eliminatory: false,
    state: SessionAlertStatus.Undefined,
  };

  const faceQuestion = session.questions?.find(
    (question) => question.type === "face"
  );
  const identityQuestion = session.questions?.find(
    (question) => question.type === "identity"
  );

  if (!faceQuestion) {
    return alert;
  }

  // find the last instance of the face question to get controls
  let lastInstance: SessionQuestionInstanceType | null = null;
  let instances: SessionQuestionInstanceType[] | [] = [];
  if (faceQuestion.instances?.length ?? 0 > 0) {
    instances = faceQuestion.instances.filter((instance) => !!instance.answers);
    lastInstance = instances.at(-1) || null;
  }

  if (identityQuestion?.instances?.length ?? 0 > 0) {
    alert.identity_faces = identityQuestion?.instances.map((a) =>
      a?.answers?.attachment_urls?.find(
        (attachment) => attachment.type === "identity_face"
      )
    );
  }

  const controls = instances.map(
    (a) =>
      a?.answers?.face_contents?.controls.map((control) => ({
        ...control,
        score: parseFloat(control.score as unknown as string),
      })) ?? []
  );

  // get scoring from scoring -> responses where id = face.id
  const faceResponseScoring = session.scoring?.responses
    ?.filter((response) => response.id === lastInstance?.id)
    .at(-1);

  if (lastInstance?.answers) {
    alert.score = faceResponseScoring?.score ?? 0;
    alert.score = lastInstance?.answers?.eval_result ? 100 : 0;
  }
  alert.state = questionScoreToAlertStatus(session, faceQuestion.id);
  alert.eliminatory = faceResponseScoring?.eliminatory ?? false;
  alert.controls = controls;
  alert.questions = instances ?? undefined;
  alert.video =
    lastInstance?.answers?.videos[lastInstance.answers.videos.length - 1];

  return alert;
};

const _generateSessionIdentityAlert = (
  session: SessionType
): SessionAlertType => {
  const alert: SessionAlertType = {
    code: "identity",
    label: "Identity",
    eliminatory: false,
    state: SessionAlertStatus.Undefined,
  };

  const identityQuestion = session.questions?.find(
    (question) => question.type === QuestionType.Identity
  );

  if (!identityQuestion) {
    return alert;
  }

  // find the last instance of the face question to get controls
  let instances: SessionQuestionInstanceType[] | [] = [];
  let lastInstance: SessionQuestionInstanceType | null = null;
  if (identityQuestion.instances?.length ?? 0 > 0) {
    instances =
      identityQuestion.instances.filter((instance) => !!instance.answers) ||
      null;
    lastInstance = instances.at(-1) || null;
  }
  const controls = instances?.map(
    (a) =>
      a?.answers?.identity_content?.analysis_result?.controls.map(
        (control) => ({
          ...control,
          score: parseFloat(control.score as unknown as string),
        })
      ) ?? []
  );
  const identityContent = instances.map(
    (a) => a?.answers.identity_content?.analysis_result
  );

  // get scoring from scoring -> responses where id = identity.id
  const identityResponseScoring = session.scoring?.responses.find(
    (response) => response.id === lastInstance?.id
  );
  if (lastInstance?.answers) {
    if (identityResponseScoring) {
      alert.score = identityResponseScoring?.score ?? 0;
    } else {
      alert.score = lastInstance?.answers?.eval_result ? 100 : 0;
    }
  }
  alert.state = questionScoreToAlertStatus(session, identityQuestion.id);
  alert.eliminatory = identityResponseScoring?.eliminatory ?? false;
  alert.intermediate_scoring = identityResponseScoring?.intermediate_scoring;
  alert.controls = controls;
  alert.identity_contents = identityContent;
  alert.questions = instances ?? [];

  return alert;
};

const _generateSessionWatchListAlert = (
  session: SessionType
): SessionAlertType => {
  const alert: SessionAlertType = {
    code: "watchlist",
    label: "Watchlist",
    eliminatory: false,
    state: SessionAlertStatus.Undefined,
  };

  // Check session warnings for watchlist

  alert.dow_jones_result = session.risk_and_compliance?.result;
  if (session.risk_and_compliance?.status != null) {
    alert.state = sessionStatusToAlertStatus(
      session.risk_and_compliance?.status
    );
    return alert;
  }
  if (
    !session?.scoring_preferences_used ||
    session.scoring_preferences_used?.watchlist.enabled
  ) {
    const hasFuzzyMatch =
      session.risk_and_compliance?.result?.body?.match?.find(
        (a: any) => a["match-type"] === "FUZZY"
      );
    const hasPreciseMatch =
      session.risk_and_compliance?.result?.body?.match?.find(
        (a: any) => a["match-type"] === "PRECISE"
      );
    const watchListWarning =
      session.session?.warnings.some(
        (warning) => warning === SessionWarnings.watch_list
      ) ||
      (hasFuzzyMatch &&
        ["flag", "consider"].includes(
          session.scoring_preferences_used.watchlist.fuzzy_match
        )) ||
      (hasPreciseMatch &&
        ["flag", "consider"].includes(
          session.scoring_preferences_used.watchlist.precise_match
        ));
    const watchListError =
      (hasFuzzyMatch &&
        ["eliminatory"].includes(
          session.scoring_preferences_used.watchlist.fuzzy_match
        )) ||
      (hasPreciseMatch &&
        ["eliminatory"].includes(
          session.scoring_preferences_used.watchlist.precise_match
        ));
    alert.score = watchListError ? 0 : watchListWarning ? 50 : 100;
    if (watchListError) {
      alert.state = SessionAlertStatus.Failed;
    } else if (watchListWarning) {
      alert.state = SessionAlertStatus.Consider;
    } else {
      alert.state = SessionAlertStatus.Success;
    }
  }

  return alert;
};

const _generateSessionCaptchaAlert = (
  session: SessionType
): SessionAlertType => {
  const alert: SessionAlertType = {
    code: "captcha",
    label: "Captcha",
    eliminatory: false,
    state: SessionAlertStatus.Undefined,
  };

  const captcha = session.captcha;

  if (!captcha) {
    return alert;
  }
  if (captcha.status != null) {
    alert.state = sessionStatusToAlertStatus(captcha.status);
    return alert;
  }

  const captchaScore = captcha.final_result;
  const threshold = session.scoring_preferences_used.captcha.threshold;
  alert.score = captchaScore >= threshold ? 100 : 0;
  alert.state =
    captchaScore >= threshold
      ? SessionAlertStatus.Success
      : SessionAlertStatus.Failed;

  return alert;
};

const _generateSessionScenarioAlert = (
  session: SessionType
): SessionAlertType => {
  const alert: SessionAlertType = {
    code: "scenario",
    label: "Scenario",
    eliminatory: false,
    state: SessionAlertStatus.Undefined,
  };

  let warnings_count = 0;
  let error_count = 0;
  let question_count = 0;

  alert.questions_eliminatory = [];
  alert.questions_information = [];
  alert.questions_worst = [];
  session.questions?.forEach((question) => {
    const instance =
      question.instances?.filter((instance) => !!instance.answers).at(-1) ||
      null;
    const responseScoring = session.scoring?.responses?.find(
      (response) => response.id === instance?.id
    );
    if (!responseScoring) {
      return;
    }
    if (responseScoring.eliminatory) {
      alert.questions_eliminatory!.push({
        question: question,
        instance: instance ?? undefined,
        scoring: responseScoring,
      });
    }

    if (
      question.type === QuestionType.Face ||
      question.type === QuestionType.Identity
    ) {
      question_count++;
      if (responseScoring.score < 70) {
        warnings_count++;
        if (responseScoring.eliminatory && responseScoring.score === 0) {
          error_count++;
        } else {
          // TODO: I dont like this, i think there is some errors in the logic
          alert.questions_worst!.push({
            question: question,
            instance: instance ?? undefined,
            scoring: responseScoring,
          });
        }
      }
    }

    if (question.type === QuestionType.Information) {
      alert.questions_information!.push({
        question: question,
        instance: instance ?? undefined,
        scoring: responseScoring,
      });
    }
  });

  if (question_count === 0) {
    return alert;
  }
  if (error_count > 0) {
    alert.score = 0;
    return alert;
  }
  if (warnings_count > 0) {
    alert.score = 50;
    return alert;
  }
  alert.score = 100;
  return alert;
};

const _generateSessionLocationAlert = (
  session: SessionType
): SessionAlertType => {
  const alert: SessionAlertType = {
    code: "location",
    label: "Location",
    eliminatory: false,
    state: SessionAlertStatus.Undefined,
  };

  alert.location_controls = {
    status: "undefined",
    score: -1,
    check: {
      country_match: "undefined",
      precise_geolocation: "undefined",
      max_distance_exceeded: "undefined",
    },
    info: {
      customer: {},
      system: {},
    },
  };

  const scoringPreferences = session.scoring_preferences_used?.geolocation;
  const location_result = session.geolocation;
  const customer_address = session.onboarding_customer;

  if (!scoringPreferences || !location_result || !customer_address) {
    return alert;
  }

  alert.location_controls = {
    ...alert.location_controls,
    info: {
      customer: customer_address,
      system: location_result,
    },
  };

  const precie_geo_exists = location_result?.geocode_distance >= 0;
  const countries_match =
    customer_address?.country.substr(0, 2).toLocaleLowerCase() ===
    location_result?.location.country_code.substr(0, 2).toLocaleLowerCase();

  if (countries_match) {
    alert.location_controls.check.country_match = "success";
    alert.location_controls.status =
      alert.location_controls.status === "undefined"
        ? "success"
        : alert.location_controls.status;
  } else if (scoringPreferences.country_different === "flag") {
    alert.location_controls.check.country_match = "warning";
    alert.location_controls.status =
      alert.location_controls.status === "error" ? "error" : "warning";
  } else if (scoringPreferences.country_different === "eliminatory") {
    alert.location_controls.check.country_match = "error";
    alert.location_controls.status = "error";
  }

  if (precie_geo_exists) {
    alert.location_controls.check.precise_geolocation = "success";
    alert.location_controls.status =
      alert.location_controls.status === "undefined"
        ? "success"
        : alert.location_controls.status;
  } else if (scoringPreferences.precise_geolocation_undefined === "flag") {
    alert.location_controls.check.precise_geolocation = "warning";
    alert.location_controls.status =
      alert.location_controls.status === "error" ? "error" : "warning";
  } else if (
    scoringPreferences.precise_geolocation_undefined === "eliminatory"
  ) {
    alert.location_controls.check.precise_geolocation = "error";
    alert.location_controls.status = "error";
  }

  if (precie_geo_exists) {
    if (
      location_result.geocode_distance <=
      scoringPreferences.ip_to_geolocation_max_distance
    ) {
      alert.location_controls.check.max_distance_exceeded = "success";
      alert.location_controls.status =
        alert.location_controls.status === "undefined"
          ? "success"
          : alert.location_controls.status;
    } else if (
      scoringPreferences.ip_to_geolocation_max_distance_exceeded === "flag"
    ) {
      alert.location_controls.check.max_distance_exceeded = "warning";
      alert.location_controls.status =
        alert.location_controls.status === "error" ? "error" : "warning";
    } else if (
      scoringPreferences.ip_to_geolocation_max_distance_exceeded ===
      "eliminatory"
    ) {
      alert.location_controls.check.max_distance_exceeded = "error";
      alert.location_controls.status = "error";
    }
  }

  alert.location_controls.score = {
    error: 0,
    warning: 50,
    success: 100,
    undefined: -1,
  }[alert.location_controls.status]!;

  let status = SessionAlertStatus.Undefined;
  if (location_result.status != null) {
    status = sessionStatusToAlertStatus(location_result.status);
  } else {
    if (alert.location_controls.status === "error") {
      status = SessionAlertStatus.Failed;
    } else if (alert.location_controls.status === "warning") {
      status = SessionAlertStatus.Consider;
    } else if (alert.location_controls.status === "success") {
      status = SessionAlertStatus.Success;
    }
  }
  alert.state = status;

  alert.score = alert.location_controls.score;

  if (session.scoring_preferences_used && !scoringPreferences.enabled) {
    alert.score = -1;
  }

  return alert;
};

export const buildNameSearchQuery = (
  customer: Partial<SessionCustomerType>
) => {
  const params: string[] = [];

  params.push("?entity_type=" + encodeURIComponent(1));

  if (customer.country) {
    params.push(
      "person_input_data.countries=" + encodeURIComponent(customer.country)
    );
  }
  if (customer.firstname) {
    params.push(
      "person_input_data.first_name=" + encodeURIComponent(customer.firstname)
    );
  }
  if (customer.lastname) {
    params.push(
      "person_input_data.last_name=" + encodeURIComponent(customer.lastname)
    );
  }
  if (customer.birthdate) {
    params.push(
      "person_input_data.date_of_birth=" +
        encodeURIComponent(customer.birthdate)
    );
  }

  return params.join("&");
};

export const flatMapControls = (
  list: SessionControlType[]
): SessionControlType[] => {
  let flatMapped: SessionControlType[] = [];
  for (let i = 0; i < list.length; i++) {
    const item = list[i];
    flatMapped.push(item);
    const controls = item.controls || item.control || [];
    if (controls && controls.length > 0) {
      flatMapped = [...flatMapped, ...flatMapControls(controls)];
    }
  }

  // Retro compatibility for sessions before 01/10/2024
  if (
    flatMapped.some((a) => a.identifier === "dataValidation.emitDate") &&
    !flatMapped.some((a) => a.identifier === "dataValidation")
  ) {
    flatMapped.push({
      identifier: "dataValidation",
      titleMsg: "Data Validation",
      result: flatMapped
        .filter((a) => a.identifier.startsWith("dataValidation"))
        .some((a) => a.result === SessionControlResult.Error)
        ? SessionControlResult.Error
        : SessionControlResult.Success,
      controls: flatMapped.filter((a) =>
        a.identifier.startsWith("dataValidation")
      ),
      resultMsg: "",
      score: 0,
      details: "",
    });
    flatMapped.push({
      identifier: "visualAuthenticity",
      titleMsg: "Visual Authenticity",
      result: flatMapped
        .filter((a) => a.identifier.startsWith("visualAuthenticity"))
        .some((a) => a.result === SessionControlResult.Error)
        ? SessionControlResult.Error
        : SessionControlResult.Success,
      controls: [],
      resultMsg: "",
      score: 0,
      details: "",
    });
    flatMapped.push({
      identifier: "imageIntegrity",
      titleMsg: "Image Integrity",
      result: flatMapped
        .filter((a) => a.identifier.startsWith("imageIntegrity"))
        .some((a) => a.result === SessionControlResult.Error)
        ? SessionControlResult.Error
        : SessionControlResult.Success,
      controls: [],
      resultMsg: "",
      score: 0,
      details: "",
    });
  }
  flatMapped = flatMapped.map((a) =>
    a.identifier !== "face"
      ? a
      : {
          ...a,
          identifier: "face.matched",
        }
  );

  return flatMapped.filter(
    (item, index) =>
      flatMapped.findIndex((tmp) => tmp.identifier === item.identifier) ===
      index
  );
};

export const getFirstNodes = (
  controls: SessionControlType[]
): SessionControlType[] => {
  const firstNodes: SessionControlType[] = [];
  const names = controls.map((control) => control.identifier);
  controls.forEach((control) => {
    let hasParent = false;
    names.forEach((name) => {
      if (control.identifier.startsWith(name) && name !== control.identifier) {
        hasParent = true;
      }
    });
    if (!hasParent) {
      firstNodes.push(control);
    }
  });
  return firstNodes;
};

export const groupControls = (key: string, list: SessionControlType[]) => {
  const controls = (list || []).filter((item) =>
    item.identifier.startsWith(key)
  );
  const scores = controls
    .filter((item) => item.result != SessionControlResult.Undefined)
    .map((item) =>
      item.result == SessionControlResult.Success
        ? 1
        : item.result == SessionControlResult.Warning
        ? 0.5
        : 0
    );
  let success: SessionControlResult = SessionControlResult.Undefined;
  if (scores.length > 0) {
    const l = scores.length;
    const reduce: number =
      scores.reduce((a, b: number) => a + b, 0 as number) / l;
    if (reduce > 0.7) {
      success = SessionControlResult.Success;
    } else if (reduce > 0.5) {
      success = SessionControlResult.Warning;
    } else {
      success = SessionControlResult.Error;
    }
  }

  return { controls, result: success };
};
