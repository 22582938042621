import { Modal, ModalContent } from "@atoms/modal/modal";
import { createContext } from "react";
import { atom, useRecoilState, useSetRecoilState } from "recoil";
import InboxPage from ".";

export const InboxModalThreadStateAtom = atom<string | null>({
  key: "InboxModalThreadStateAtom",
  default: null,
});

export const InboxModalAtom = atom<string | null>({
  key: "InboxModalAtom",
  default: null,
});

export const InboxModalContext = createContext({
  inModal: false,
  customerId: "",
});

export const InboxModal = () => {
  const [open, setOpen] = useRecoilState(InboxModalAtom);
  const setStateSelectedThread = useSetRecoilState(InboxModalThreadStateAtom);
  return (
    <Modal
      style={{
        height: "calc(100vh - 64px)",
        width: "calc(100vw - 64px)",
        maxWidth: "none",
        padding: 0,
      }}
      open={!!open}
      onClose={() => {
        setOpen(null);
        setStateSelectedThread(null);
      }}
    >
      <ModalContent>
        <InboxModalContext.Provider
          value={{
            inModal: true,
            customerId: open!,
          }}
        >
          <InboxPage />
        </InboxModalContext.Provider>
      </ModalContent>
    </Modal>
  );
};
