import { Tag } from "@atoms/badge/tag";
import { Button } from "@atoms/button/button";
import SelectMultiple from "@atoms/input/input-select-multiple";
import { PageBlockHr } from "@atoms/layout/page-block";
import Link from "@atoms/link";
import Select from "@atoms/select";
import { BaseSmall, Info, Menu, SectionSmall } from "@atoms/text";
import { useCustomerTransactionsAggregate } from "@features/customers/state/use-customer-transactions-aggregates";
import { CustomerAllDetailType } from "@features/customers/types";
import { ROUTES } from "@features/routes";
import { formatNumber } from "@features/utils";
import { formatAmount } from "@features/utils/strings";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/outline";
import {
  ChartData,
  Chart as ChartJS,
  Legend,
  LinearScale,
  PointElement,
  TimeScale,
  Tooltip,
} from "chart.js";
import "chartjs-adapter-moment";
import Annotations from "chartjs-plugin-annotation";
import _ from "lodash";
import { useEffect, useRef, useState } from "react";
import { Bar, Line, getElementAtEvent } from "react-chartjs-2";
import { alignDates, dailyAggregates } from "./utils";
import { useCustomers } from "@features/customers/state/use-customers";

ChartJS.register(
  LinearScale,
  PointElement,
  Tooltip,
  Legend,
  TimeScale,
  Annotations
);

const CHART_PAST18_MONTHS_LABELS = Array.from({ length: 18 }, (_, i) => {
  const d = new Date();
  d.setMonth(d.getMonth() - i);
  return `${String(d.getFullYear()).slice(-2)}/${String(
    d.getMonth() + 1
  ).padStart(2, "0")}`;
}).reverse();

const CHART_COLOR_IN = "#7fff7f";
const CHART_BORDER_COLOR_IN = "#03D903";
const CHART_MAX_MIN_IN = "#03D9036D";
const CHART_COLOR_OUT = "#ff7f7f";
const CHART_BORDER_COLOR_OUT = "#E80B0B";
const CHART_MAX_MIN_OUT = "#E80B0B7B";

const CHART_OPTIONS = {
  responsive: true,
  scales: {
    y: {
      beginAtZero: false,
    },
  },
  plugins: {
    legend: {
      position: "bottom",
    },
    title: {
      display: true,
    },
  },
  interaction: {
    mode: "index",
    intersect: false,
  },
};

export default function CustomerTransactionsChart({
  customer,
  retractableDetails,
}: {
  customer: CustomerAllDetailType;
  retractableDetails: boolean;
}) {
  const [displayMore, setDisplayMore] = useState(!retractableDetails);
  const id = customer.details.customer.external_id;
  const [monthlyFilter, setMonthlyFilter] = useState({
    month: new Date().toISOString().slice(0, 7),
    date_span: "360",
    status: "all",
    type: "all",
    direction: "all",
    context: "all",
  });
  const { aggregates, refresh, loading } = useCustomerTransactionsAggregate(
    id ?? ""
  );

  const { schema } = useCustomers();
  const kytContexts = [
    ...(schema.find((a) => a.external_key === "kyt_contexts")
      ?.possible_values || ["no_context"]),
    "all",
  ];

  const overviewChartRef = useRef(null);

  const [montlyData, setMontlyData] = useState<ChartData>();
  const [sumMontlyData, setSumMontlyData] = useState<ChartData>();
  const [rollingData, setRollingData] = useState<ChartData>();

  const types = ["all", "exchange", "bank", "card", "crypto"];

  useEffect(() => {
    if (id) {
      refresh();
    }
  }, [id]);

  // if aggregate change, adapt the montly data and rolling data
  useEffect(() => {
    if (!aggregates) return;

    const daily = dailyAggregates(aggregates.monthly_values ?? []);

    const filtered = daily.filter(
      (v) =>
        (v.context === monthlyFilter.context ||
          monthlyFilter.context === "all") &&
        v.status === monthlyFilter.status &&
        v.type === monthlyFilter.type &&
        (monthlyFilter.direction === "all" ||
          v.direction === monthlyFilter.direction)
    );

    const focusFiltered = filtered.filter((v) => {
      if (monthlyFilter.date_span === "custom") {
        return (
          v.date.split("-")[0] === monthlyFilter.month.split("-")[0] &&
          v.date.split("-")[1] === monthlyFilter.month.split("-")[1]
        );
      } else {
        return (
          new Date(v.date).getTime() >=
          new Date(
            new Date().setDate(
              new Date().getDate() - parseInt(monthlyFilter.date_span)
            )
          ).getTime()
        );
      }
    });

    // montly data
    const mDataLabels = _.uniq(_.sortBy(focusFiltered.map((d) => d.date)));
    const mData = {
      labels: mDataLabels,
      datasets: [
        {
          label: `Min/Max (out)`,
          data: alignDates(
            mDataLabels,
            focusFiltered.filter((a) => a.direction === "out")
          ).map((v) => [-v.min, -v.max]),
          backgroundColor: CHART_MAX_MIN_OUT,
        },
        {
          label: `Min/Max (in)`,
          data: alignDates(
            mDataLabels,
            focusFiltered.filter((a) => a.direction === "in")
          ).map((v) => [v.min, v.max]),
          backgroundColor: CHART_MAX_MIN_IN,
        },
        {
          label: `Transactions (in)`,
          barPercentage: 0.5,
          data: alignDates(
            mDataLabels,
            focusFiltered.filter((a) => a.direction === "in")
          ).map((v) => v.sum),
          backgroundColor: CHART_COLOR_IN,
          borderColor: CHART_BORDER_COLOR_IN,
        },
        {
          label: `Transactions (out)`,
          barPercentage: 0.5,
          data: alignDates(
            mDataLabels,
            focusFiltered.filter((a) => a.direction === "out")
          ).map((v) => -v.sum),
          backgroundColor: CHART_COLOR_OUT,
          borderColor: CHART_BORDER_COLOR_OUT,
        },
      ],
    };
    setMontlyData(mData as any);

    // montly data sum
    const mSumDataLabels = _.uniq(_.sortBy(filtered.map((d) => d.date)));
    const mSumData = {
      labels: mSumDataLabels,
      datasets: [
        {
          label: `Transactions (in)`,
          data: alignDates(
            mSumDataLabels,
            filtered.filter((v) => v.direction === "in")
          ).map((v) => v.sum),
          fill: true,
          backgroundColor: CHART_COLOR_IN,
          borderColor: CHART_BORDER_COLOR_IN,
          barPercentage: 2,
        },
        {
          label: `Transactions (out)`,
          data: alignDates(
            mSumDataLabels,
            filtered.filter((v) => v.direction === "out")
          ).map((v) => -v.sum),
          fill: true,
          backgroundColor: CHART_COLOR_OUT,
          borderColor: CHART_BORDER_COLOR_OUT,
          barPercentage: 2,
        },
      ],
    };
    setSumMontlyData(mSumData as any);

    // rolling data
    const rData = {
      labels: CHART_PAST18_MONTHS_LABELS,
      datasets: (aggregates.rolling_values ?? [])
        .filter(
          (aggregate) =>
            aggregate.status === monthlyFilter.status &&
            aggregate.type === monthlyFilter.type &&
            (monthlyFilter.direction === "all" ||
              aggregate.direction === monthlyFilter.direction)
        )
        .map((aggregate) => {
          const direction = aggregate.direction;
          const isIn = direction === "in";
          const color = isIn ? CHART_COLOR_IN : CHART_COLOR_OUT;
          const borderColor = isIn
            ? CHART_BORDER_COLOR_IN
            : CHART_BORDER_COLOR_OUT;
          const colorMaxMin = isIn ? CHART_MAX_MIN_IN : CHART_MAX_MIN_OUT;
          const values = isIn
            ? aggregate.sum
            : aggregate.sum.map((value) => -value);

          return [
            {
              type: "bar" as const,
              label: `Min/Max ${direction}`,
              barPercentage: 0.2,
              data: CHART_PAST18_MONTHS_LABELS.map((_, index) => [
                isIn ? aggregate.min[index] : -aggregate.min[index],
                isIn ? aggregate.max[index] : -aggregate.max[index],
              ]),
              backgroundColor: colorMaxMin,
            },
            {
              label: `Transactions (${direction})`,
              fill: true,
              data: values,
              backgroundColor: color,
              borderColor: borderColor,
            },
          ].flat();
        })
        .flat(),
    };
    setRollingData(rData as any);
  }, [aggregates, monthlyFilter]);

  if (loading) {
    return <div>Loading...</div>;
  }

  const rollingIndex =
    monthlyFilter.date_span === "custom"
      ? 0
      : Math.floor((parseInt(monthlyFilter.date_span) - 1) / 30);

  return (
    <div className="w-full">
      <div className="float-right space-x-4">
        <div className="inline-flex flex-row justify-center items-center space-x-2">
          <Link
            to={
              ROUTES.LiveTransactions +
              `?q=customers%3A~"${customer.details.customer.external_id}"&map=%7B%7D`
            }
            className="align-top"
          >
            <Button size="sm" theme="outlined">
              Open Go!KYT
            </Button>
          </Link>
        </div>
      </div>

      <SectionSmall>
        Transactions
        <BaseSmall className="ml-2 cursor-pointer">
          {retractableDetails && (
            <Link onClick={() => setDisplayMore(!displayMore)}>
              {displayMore ? "Show less" : "Show details"}
            </Link>
          )}
        </BaseSmall>
      </SectionSmall>

      {!displayMore && <Info>Displaying 12 rolling months</Info>}

      {displayMore && (
        <>
          <div className="my-4">
            <div className="flex flex-row justify-center items-center space-x-2 w-2/3">
              <div className="basis-1/2">
                <SelectMultiple
                  className="min-w-40"
                  size="md"
                  selectionLimit={1}
                  options={kytContexts.map((context) => ({
                    label: "Context: " + context,
                    value: context,
                  }))}
                  value={[monthlyFilter.context]}
                  onChange={(contexts) => {
                    setMonthlyFilter({
                      ...monthlyFilter,
                      context: contexts[0],
                    });
                  }}
                />
              </div>
              <div className="basis-1/2">
                <Select
                  className="basis-1/2"
                  value={monthlyFilter.date_span}
                  onChange={(e) =>
                    setMonthlyFilter({
                      ...monthlyFilter,
                      date_span: e.target.value,
                    })
                  }
                >
                  <option value="custom">Custom month</option>
                  <option value="30">Rolling 30d</option>
                  <option value="90">Rolling 90d</option>
                  <option value="180">Rolling 6m</option>
                  <option value="360">Rolling 12m</option>
                  <option value="540">Rolling 18m</option>
                </Select>
              </div>
              {monthlyFilter.date_span === "custom" && (
                <>
                  <Button
                    className="!px-3"
                    onClick={() => {
                      const d = new Date(monthlyFilter.month);
                      d.setMonth(d.getMonth() - 1);
                      setMonthlyFilter({
                        ...monthlyFilter,
                        month: `${d.getFullYear()}-${String(
                          d.getMonth() + 1
                        ).padStart(2, "0")}`,
                      });
                    }}
                    theme="default"
                    size="sm"
                  >
                    <ChevronLeftIcon className="w-4 h-4 shrink-0" />
                  </Button>
                  <Menu className="text-bold mt-1 whitespace-nowrap">
                    {monthlyFilter.month}
                  </Menu>
                  <Button
                    className="!px-3"
                    disabled={
                      new Date(monthlyFilter.month).getMonth() ===
                        new Date().getMonth() &&
                      new Date(monthlyFilter.month).getFullYear() ===
                        new Date().getFullYear()
                    }
                    onClick={() => {
                      const d = new Date(monthlyFilter.month);
                      d.setMonth(d.getMonth() + 1);
                      setMonthlyFilter({
                        ...monthlyFilter,
                        month: `${d.getFullYear()}-${String(
                          d.getMonth() + 1
                        ).padStart(2, "0")}`,
                      });
                    }}
                    theme="default"
                    size="sm"
                  >
                    <ChevronRightIcon className="w-4 h-4 shrink-0" />
                  </Button>
                </>
              )}
            </div>
            <div className="flex flex-row space-x-2 w-full float-right my-2">
              <SelectMultiple
                className="min-w-40"
                size="md"
                selectionLimit={1}
                options={types.map((type) => ({
                  label: "Type: " + type,
                  value: type,
                }))}
                value={[monthlyFilter.type]}
                onChange={(type) => {
                  setMonthlyFilter({
                    ...monthlyFilter,
                    type: type[0],
                  });
                }}
              />
              <SelectMultiple
                size="md"
                className="min-w-40"
                selectionLimit={1}
                options={["all", "in", "out"].map((direction) => ({
                  label: "Direction: " + direction,
                  value: direction,
                }))}
                value={[monthlyFilter.direction]}
                onChange={(direction) => {
                  setMonthlyFilter({
                    ...monthlyFilter,
                    direction: direction[0],
                  });
                }}
              />
              <SelectMultiple
                size="md"
                className="min-w-40"
                selectionLimit={1}
                options={["all", "allowed", "blocked"].map((status) => ({
                  label: "Status: " + status,
                  value: status,
                }))}
                value={[monthlyFilter.status]}
                onChange={(status) => {
                  setMonthlyFilter({
                    ...monthlyFilter,
                    status: status[0],
                  });
                }}
              />
            </div>
          </div>
          <PageBlockHr />
        </>
      )}

      {monthlyFilter.date_span !== "custom" && (
        <div className="mt-2 space-x-2 w-max flex-row flex">
          <Tag
            noColor
            className="bg-green-500 text-white block !px-3 !py-1 text-md"
          >
            {formatAmount(
              aggregates?.rolling_values?.filter(
                (v) =>
                  (v.context === monthlyFilter.context ||
                    monthlyFilter.context === "all") &&
                  v.status === monthlyFilter.status &&
                  v.type === monthlyFilter.type &&
                  v.direction === "in"
              )[0]?.sum[rollingIndex] || 0
            )}{" "}
            € in
          </Tag>
          <Tag
            noColor
            className="bg-red-500 text-white block !px-3 !py-1 text-md"
          >
            {formatAmount(
              aggregates?.rolling_values?.filter(
                (v) =>
                  (v.context === monthlyFilter.context ||
                    monthlyFilter.context === "all") &&
                  v.status === monthlyFilter.status &&
                  v.type === monthlyFilter.type &&
                  v.direction === "out"
              )[0]?.sum[rollingIndex] || 0
            )}{" "}
            € out
          </Tag>
          <Tag
            noColor
            className="bg-orange-500 text-white block !px-3 !py-1 text-md"
          >
            {formatAmount(
              aggregates?.rolling_values?.filter(
                (v) =>
                  (v.context === monthlyFilter.context ||
                    monthlyFilter.context === "all") &&
                  v.status === monthlyFilter.status &&
                  v.type === monthlyFilter.type
              )[0]?.max[rollingIndex] || 0
            )}{" "}
            € max
          </Tag>
          <Tag className="block !px-3 !py-1 text-md">
            {formatNumber(
              aggregates?.rolling_values?.filter(
                (v) =>
                  (v.context === monthlyFilter.context ||
                    monthlyFilter.context === "all") &&
                  v.status === monthlyFilter.status &&
                  v.type === monthlyFilter.type
              )[0]?.count[rollingIndex] || 0
            )}{" "}
            transactions
          </Tag>
        </div>
      )}

      {displayMore && (
        <div className="w-full flex flex-col mt-4">
          {sumMontlyData && (
            <div style={{ height: "80px" }} className="-mb-4">
              <Bar
                ref={overviewChartRef}
                onClick={(e) => {
                  const month = (sumMontlyData.labels as any)[
                    getElementAtEvent(overviewChartRef.current!, e)?.[0]?.index
                  ];

                  if (month)
                    setMonthlyFilter({
                      ...monthlyFilter,
                      date_span: "custom",
                      month: month.slice(0, 7),
                    });
                }}
                data={sumMontlyData as any}
                options={
                  {
                    ...CHART_OPTIONS,
                    maintainAspectRatio: false,
                    scales: {
                      x: {
                        type: "time",
                        stacked: true,

                        // Display only the month
                        time: {
                          unit: "month",
                        },

                        // Place grid at the beginning of the month
                        grid: {
                          offset: false,
                          display: true,
                          drawOnChartArea: false,
                        },

                        // Force max to today and min to 1 year ago
                        max: new Date().getTime(),
                        min: new Date(
                          new Date().setFullYear(new Date().getFullYear() - 1)
                        ).getTime(),
                      },
                      y: {
                        display: false,
                      },
                    },
                    display: false,
                    plugins: {
                      legend: {
                        display: false,
                      },
                      annotation: {
                        annotations: {
                          annotation1: {
                            type: "box",
                            backgroundColor: "rgba(128, 128, 128, 0.2)",
                            borderColor: "rgba(128, 128, 128, 0.2)",
                            borderWidth: 1,
                            xMax:
                              monthlyFilter.date_span === "custom"
                                ? new Date(
                                    new Date(
                                      monthlyFilter.month + "-01"
                                    ).setMonth(
                                      new Date(
                                        monthlyFilter.month + "-01"
                                      ).getMonth() + 1
                                    )
                                  ).getTime()
                                : Date.now(),
                            xMin:
                              monthlyFilter.date_span === "custom"
                                ? new Date(
                                    monthlyFilter.month + "-01"
                                  ).getTime()
                                : new Date(
                                    Date.now() -
                                      parseInt(monthlyFilter.date_span) *
                                        24 *
                                        60 *
                                        60 *
                                        1000
                                  ).getTime(),
                          },
                        },
                      },
                    },
                  } as any
                }
              />
            </div>
          )}
          {montlyData && (
            <div style={{ height: "300px" }}>
              <Bar
                data={montlyData as any}
                options={
                  {
                    ...CHART_OPTIONS,
                    maintainAspectRatio: false,
                    scales: {
                      y: {
                        type: "linear",
                        // Set unit to currency
                        ticks: {
                          callback: function (value: number) {
                            return value.toLocaleString("en-US", {
                              style: "currency",
                              currency: "EUR",
                            });
                          },
                        },
                        position: "right",
                      },
                      x: {
                        type: "time",
                        time: {
                          unit: "day",
                        },
                        stacked: true,
                        grid: {
                          display: false,
                        },
                      },
                    },
                  } as any
                }
              />{" "}
            </div>
          )}
        </div>
      )}

      {false && (
        <div className="w-full">
          <SectionSmall>Rolling Aggregate</SectionSmall>
          {rollingData && (
            <Line
              options={
                { ...CHART_OPTIONS, scales: { x: { stacked: true } } } as any
              }
              data={rollingData as any}
            />
          )}
        </div>
      )}
    </div>
  );
}
