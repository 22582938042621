import { useScenarios } from "@features/scenarios/state/use-scenarios";
import { delayRequest } from "@features/utils";
import { LoadingAtom } from "@features/utils/loading";
import { useCallback } from "react";
import { useRecoilState } from "recoil";
import { SessionsApiClient } from "../api-client/api-client";
import { SessionsRequestOptions } from "../types";
import { SessionsListAtom } from "./store";
import { getSessionAvatar, getVersion } from "../utils";

export const useSessions = () => {
  const [sessions, setSessions] = useRecoilState(SessionsListAtom);
  const [loading, setLoading] = useRecoilState(LoadingAtom("useSessions"));
  const { scenarios } = useScenarios();

  const refresh = useCallback(
    async (options?: SessionsRequestOptions) => {
      setLoading(true);
      delayRequest("useSessionsRefresh", async () => {
        // TODO: Remove this when the API is fixed (add result_label /category to the fitler)
        let sessionsAPI = await SessionsApiClient.getSessions({ ...options });

        if (options && options.result_label) {
          sessionsAPI = {
            ...sessionsAPI,
            data: sessionsAPI.data.filter((session) => {
              const scenario = scenarios!.data.find(
                (scenario) => scenario.code === session.scenario_ref
              );
              return scenario && scenario.result_label === options.result_label;
            }),
          };
        }
        setSessions(sessionsAPI);
        setLoading(false);
      });
    },
    [setSessions, setLoading]
  );

  const fetchSessions = async (options: SessionsRequestOptions) => {
    return await SessionsApiClient.getSessions({ ...options });
  };

  const fetchAvatarSessionWithExternalID = async (
    options: SessionsRequestOptions
  ) => {
    setLoading(true);
    const customerLatestSessions = (await fetchSessions({ ...options })).data;
    if (!customerLatestSessions) {
      setLoading(false);
      return;
    }

    if (customerLatestSessions.length > 0) {
      let i = 0;
      let tempAvatar = undefined;
      while (!tempAvatar && i < customerLatestSessions.length) {
        const session = await SessionsApiClient.getSession(
          customerLatestSessions[i].session_id
        );
        session.version = getVersion(session);
        tempAvatar = getSessionAvatar(session);
        i++;
      }

      setLoading(false);
      return tempAvatar;
    }
    setLoading(false);
  };

  return {
    sessions,
    fetchSessions,
    fetchAvatarSessionWithExternalID,
    loading,
    scenarios,
    refresh,
  };
};
