import { Button } from "@atoms/button/button";
import { ButtonConfirm } from "@atoms/button/confirm";
import { Input } from "@atoms/input/input-text";
import { Modal, ModalContent } from "@atoms/modal/modal";
import { Base, Info, SectionSmall } from "@atoms/text";
import { useAgents } from "@features/agents/state/use-agents";
import { useHasAccess } from "@features/auth/state/use-access";
import { CustomerAllDetailType } from "@features/customers/types";
import { useDocumentsLabels } from "@features/documents-labels/state/use-documents-labels";
import { useCustomerNotes } from "@features/documents/state/user-customer-notes";
import { formatTime } from "@features/utils/dates";
import { useState } from "react";
import ReactMarkdown from "react-markdown";

export const Notes = ({
  customer,
  tags,
}: {
  customer: CustomerAllDetailType;
  tags?: string[];
}) => {
  const { notes, total, nextPage, addNote, loading } = useCustomerNotes(
    customer.details.customer.external_id,
    tags
  );
  const { getAgentForClient } = useAgents();
  const agents = getAgentForClient(true);
  const hasAccess = useHasAccess();

  const [noteModal, setNoteModal] = useState(false);
  const [note, setNote] = useState("");

  const { labels } = useDocumentsLabels();

  return (
    <div>
      <Modal
        className="min-w-xl"
        open={noteModal}
        onClose={() => setNoteModal(false)}
      >
        <ModalContent title="Add note">
          <Input
            multiline
            rows={5}
            placeholder="Note with **markdown** support"
            value={note}
            onChange={(e) => setNote(e.target.value)}
          />
          <ButtonConfirm
            className="float-right mt-4"
            onClick={() => {
              addNote(note);
              setNoteModal(false);
            }}
          >
            Add note
          </ButtonConfirm>
        </ModalContent>
      </Modal>

      {hasAccess("CUSTOMER_UPDATE") && (
        <Button
          className="float-right"
          size="sm"
          onClick={() => setNoteModal(true)}
        >
          Add note
        </Button>
      )}

      <SectionSmall className="mt-1 mb-2">Notes</SectionSmall>

      {notes.map((note) => (
        <div key={note.id} className="mb-2">
          <Base className="customer-notes">
            <ReactMarkdown>{note.content}</ReactMarkdown>
          </Base>
          <Info>
            {
              (agents || []).find(
                (u) => u.agent_id === note.last_revision_agent_id
              )?.name
            }{" "}
            {formatTime(note.created_at * 1000)}
            {" • "}
            {(note.tags || [])
              .map((b) => labels?.find((a) => a.code === b)?.name || b)
              .join(", ") || "No tags"}
          </Info>
        </div>
      ))}
      {notes.length === 0 && <Info>No notes yet.</Info>}
      {notes.length < total && (
        <Button
          onClick={() => nextPage()}
          loading={loading}
          className="mt-4"
          theme="outlined"
          size={"sm"}
        >
          Load more
        </Button>
      )}
    </div>
  );
};
