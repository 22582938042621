import _ from "lodash";

export function flattenKeys(object: any, initialPathPrefix = ""): any {
  if (
    !object ||
    typeof object !== "object" ||
    object?.length === 0 ||
    Object.keys(object).length === 0
  ) {
    return [{ [initialPathPrefix]: object }];
  }

  const prefix = initialPathPrefix
    ? Array.isArray(object)
      ? initialPathPrefix
      : `${initialPathPrefix}.`
    : "";

  return Object.keys(object)
    .flatMap((key) =>
      flattenKeys(
        object[key],
        Array.isArray(object) ? `${prefix}[${key}]` : `${prefix}${key}`
      )
    )
    .reduce((acc, path) => ({ ...acc, ...path }));
}

/**
 * Takes an object and return a [tag:value, key2:value1, key2:value2] array
 */
export const objectToTags = (object: any, initialPathPrefix = ""): any => {
  const flatten = flattenKeys(object, initialPathPrefix);
  // Replace all "\.\[[0-9]+\]" with "" in keys
  return _.flatten(
    Object.keys(flatten)
      .map((key) => {
        const keyNoArray = key.replace(/\.\[[0-9]+\]/g, "");
        const value = flatten[key];
        if (typeof value === "string") {
          return value.split(" ").map((v: any) => {
            return `${keyNoArray}:${v}`;
          });
        } else {
          return [];
        }
      })
      .filter((e) => e.length > 0)
  );
};
