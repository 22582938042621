import { Tag } from "@atoms/badge/tag";
import { Button } from "@atoms/button/button";
import Link from "@atoms/link";
import { Base, Info, InfoSmall } from "@atoms/text";
import { KytTransaction } from "@features/kyt/types";
import {
  KYT_TRANSACTION_STATES_COLORS,
  KYT_TRANSACTION_STATES_LABELS,
} from "@features/kyt/utils";
import { ROUTES } from "@features/routes";
import { formatTime } from "@features/utils/dates";
import { formatAmount } from "@features/utils/strings";
import { BuildingOfficeIcon, UserIcon } from "@heroicons/react/24/outline";
import SessionScore from "@views/client/vid/sessions/components/session-score";

export const defaultColumns = [
  "external_id",
  "from",
  "to",
  "amount",
  "date",
  "context",
  "score",
  "status",
  "actions",
];

export const availableColumns = Object.assign({
  external_id: {
    title: "#",
    orderable: false,
    className: "overflow-hidden text-ellipsis",
    thClassName: "w-40",
    render: (row: KytTransaction) => <Info>{row.external_id}</Info>,
  },
  from: {
    title: "From",
    orderable: false,
    render: (row: KytTransaction) => (
      <div className="flex flex-col items-start w-full font-mono whitespace-nowrap">
        <Base>
          {row.from.account_type === "F" ? (
            <BuildingOfficeIcon className="h-5 w-5 inline mr-2 shrink-0" />
          ) : (
            <UserIcon className="h-5 w-5 inline mr-2 shrink-0" />
          )}
          {row.from.full_name}
        </Base>
        <Info>
          <InfoSmall className="ml-2">{row.from.external_id}</InfoSmall>
        </Info>
      </div>
    ),
  },
  to: {
    title: "To",
    orderable: false,
    render: (row: KytTransaction) => (
      <div className="flex flex-col items-start w-full font-mono whitespace-nowrap">
        <Base>
          {row.to.account_type === "F" ? (
            <BuildingOfficeIcon className="h-5 w-5 inline mr-2 shrink-0" />
          ) : (
            <UserIcon className="h-5 w-5 inline mr-2 shrink-0" />
          )}
          {row.to.full_name}
        </Base>
        <Info>
          <InfoSmall className="ml-2">{row.to.external_id}</InfoSmall>
        </Info>
      </div>
    ),
  },
  amount: {
    title: "Amount",
    orderable: false,
    render: (row: KytTransaction) => (
      <div className="flex flex-col items-end w-full font-mono  whitespace-nowrap">
        <Base>
          {formatAmount(row.amount, row.currency)} <Info>{row.currency}</Info>
        </Base>
        {row.converted_currency !== row.currency && (
          <Info>
            ~ {formatAmount(row.converted_amount, row.converted_currency)}
            <InfoSmall className="ml-2">{row.converted_currency}</InfoSmall>
          </Info>
        )}
      </div>
    ),
  },
  date: {
    title: "Date",
    orderable: false,
    className:
      "overflow-hidden text-ellipsis text-right justify-end whitespace-nowrap",
    thClassName: "w-70",
    render: (row: KytTransaction) => (
      <Info>
        {formatTime(row.date, undefined, {
          keepDate: true,
          keepTime: true,
        })}
      </Info>
    ),
  },
  context: {
    title: "Context",
    orderable: false,
    render: (row: KytTransaction) => (
      <div className="flex flex-col items-start w-full font-mono  whitespace-nowrap">
        <Base>{row.context}</Base>
      </div>
    ),
  },
  score: {
    title: "Score",
    orderable: false,
    className: "overflow-hidden text-ellipsis text-right justify-end",
    thClassName: "w-40",
    render: (row: KytTransaction) => (
      <SessionScore
        text={row.score !== null ? row.score.toFixed(0) + " %" : "-"}
        status={
          row.score === null
            ? "slate"
            : row.state === 1
            ? "red"
            : row.state === 2
            ? "yellow"
            : "green"
        }
      />
    ),
  },
  status: {
    title: "Status",
    orderable: false,
    thClassName: "w-40",
    render: (row: KytTransaction) => (
      <div className="flex items-center flex-row capitalize whitespace-nowrap">
        <Tag
          noColor
          className={
            "mx-1 my-1 text-white bg-" +
            (KYT_TRANSACTION_STATES_COLORS[row.state] || "slate-500")
          }
        >
          {KYT_TRANSACTION_STATES_LABELS[row.state]}
        </Tag>
      </div>
    ),
  },
  //   "":      {
  //     title: "",
  //     className: "text-right",
  //     headClassName: "justify-end",
  //     thClassName: "w-24",
  //     render: (row: KytTransaction) => (
  //       <>
  //         <AssigneeTag type="transaction" id={row.id} />
  //       </>
  //     ),
  //   },

  actions: {
    title: "Actions",
    orderable: false,
    className: "text-right",
    headClassName: "justify-end",
    thClassName: "w-24",
    render: (row: KytTransaction) => (
      <Link to={ROUTES.TransactionView.replace(":id", row.id)}>
        <Button size="sm" theme="outlined">
          View
        </Button>
      </Link>
    ),
  },
});
