import { Tag } from "@atoms/badge/tag";
import { Info, InfoSmall } from "@atoms/text";
import { useHasAccess } from "@features/auth/state/use-access";
import { CustomerAllDetailType } from "@features/customers/types";
import { useDashboard } from "@features/dashboard/state/hooks";
import { formatDate } from "@features/utils/dates";
import { PressShortReview } from "./reviews/press";

export const ComputedFields = ({
  customer,
}: {
  customer: CustomerAllDetailType;
}) => {
  const { scan } = useDashboard();
  const lastScanDate = scan?.last_scan_info?.end_date;

  const hasAccess = useHasAccess();

  return (
    <>
      <div className="grid gap-4 grid-cols-2 sm:grid-cols-5">
        <div>
          <span
            data-tooltip={
              "Related to Go!Scan sanctioned entities (or relative and close associates)"
            }
            tabIndex={0}
          >
            <Info>Sanction (or RCA)</Info>
          </span>

          <br />
          <div className="overflow-hidden mr-2 text-base">
            {customer.details.se.value && (
              <span
                data-tooltip={
                  "Confirmed alert exists for this customer or this value was manually set to true"
                }
                tabIndex={0}
              >
                <Tag noColor className="bg-red-500 text-white">
                  Confirmed SAN
                </Tag>
              </span>
            )}
            {customer.details.may_be_san.value && (
              <span
                data-tooltip={"New or pending alert exists for this customer"}
                tabIndex={0}
              >
                <Tag noColor className="bg-yellow-500 text-white">
                  Unconfirmed SAN
                </Tag>
              </span>
            )}
            {!customer.details.may_be_san.value &&
              !customer.details.se.value && <Tag>N/A</Tag>}
          </div>
          <InfoSmall>
            {lastScanDate ? formatDate(lastScanDate) : "Never checked"}
          </InfoSmall>
        </div>
        {hasAccess("PRESS") && <PressShortReview customer={customer} />}
        <div>
          <span
            data-tooltip={
              "Related to Go!Scan politically exposed persons (or relative and close associates)"
            }
            tabIndex={0}
          >
            <Info>PEP (or RCA)</Info>
          </span>

          <br />
          <div className="overflow-hidden mr-2 text-base">
            {customer.details.pep.value && (
              <span
                data-tooltip={
                  "Confirmed alert exists for this customer or this value was manually set to true"
                }
                tabIndex={0}
              >
                <Tag noColor className="bg-red-500 text-white">
                  Confirmed PEP
                </Tag>
              </span>
            )}
            {customer.details.may_be_pep.value && (
              <span
                data-tooltip={"New or pending alert exists for this customer"}
                tabIndex={0}
              >
                <Tag noColor className="bg-yellow-500 text-white">
                  Unconfirmed PEP
                </Tag>
              </span>
            )}
            {!customer.details.may_be_pep.value &&
              !customer.details.pep.value && <Tag>N/A</Tag>}
          </div>
          <InfoSmall>
            {lastScanDate ? formatDate(lastScanDate) : "Never checked"}
          </InfoSmall>
        </div>

        {customer.details.tm.value && (
          <div>
            <Info>Transactions monitored (t)TM)</Info>
            <br />
            <div className="overflow-hidden mr-2 text-base">
              <Tag>Yes</Tag>
            </div>
            <InfoSmall>{formatDate(new Date())}</InfoSmall>
          </div>
        )}
        {customer.details.str.value && (
          <div>
            <Info>Suspicious transaction report (STR)</Info>
            <br />
            <div className="overflow-hidden mr-2 text-base">
              <Tag>Yes</Tag>
            </div>
          </div>
        )}
      </div>
    </>
  );
};
