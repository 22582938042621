import { useQuery } from "react-query";
import { useRecoilState } from "recoil";
import { KytApiClient } from "../api-client/api-client";
import { KytTransaction } from "../types";
import { TransactionsQueryInputAtom } from "./store";
import { RestSearchRequest } from "@components/search-bar/utils/types";

export const useTransactions = () => {
  const [query, setQuery] = useRecoilState(TransactionsQueryInputAtom);

  const schema = useQuery({
    queryKey: ["kyt", "transactions", "schema"],
    queryFn: KytApiClient.getTransactionsSearchSchema,
  });

  const fetchTransactions = async (query: RestSearchRequest) => {
    return await KytApiClient.getTransactionsAdvanced(
      query.query,
      query.options
    );
  };

  const newSearchTransactions = useQuery<{
    data: KytTransaction[];
    total: number;
  }>({
    keepPreviousData: true,
    queryKey: ["kyt", "transactions", query.query, query.options],
    queryFn: async () => {
      return await fetchTransactions(query);
    },
    cacheTime: 2000,
  });

  return {
    schema,
    transactions: newSearchTransactions?.data,
    query,
    setQuery,
    loading: newSearchTransactions.isFetching || schema.isLoading,
    refresh: newSearchTransactions.refetch,
    fetchTransactions,
  };
};
