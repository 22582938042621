import { AppFeatureAccess } from "@features/access";
import { useCallback } from "react";
import { useAuth } from "./hooks";
import { PermissionKey } from "../types";

export const useHasAccess = () => {
  const { user } = useAuth();
  return useCallback(
    (
      requested: keyof typeof AppFeatureAccess,
      requestedReviewGroups: string[] | null = null
    ) => {
      const acos = user?.permissions.acos || [];
      if (requestedReviewGroups) {
        const access = AppFeatureAccess[requested].find((p) =>
          acos.includes(p as PermissionKey)
        );
        return (
          !!access &&
          !!user?.permissions.acos_to_review_groups[
            access as PermissionKey
          ]?.some((review_group) =>
            requestedReviewGroups.includes(review_group)
          )
        );
      }

      return AppFeatureAccess[requested].some((p) =>
        acos.includes(p as PermissionKey)
      );
    },
    [user?.permissions]
  );
};
